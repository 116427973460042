<!-- 小说阅读器底部菜单栏 -->
<template>
    <transition name="fade">
        <div class="menu-wrap" v-show="show" @click.passive="openDirectory()">
            <div class="icon directory-icon"></div>
            <span class="txt">目录</span>
        </div>
    </transition>
</template>

<script>
export default {
    // eslint-disable-next-line vue/no-reserved-component-names
    name: 'Menu',
    props: {
        show: {
            type: Boolean,
            default: false,
        },
    },
    methods: {
        async openDirectory() {
            this.DialogController.syncShow({
                component: () => import(/* webpackChunkName: "dialog" */ './Category/index.vue'),
                props: {
                    withCloseBtn: false,
                    animation: 'slide',
                },
                contentProps: {
                    updateComic: (id) => {
                        this.$emit('update', id);
                    },
                },
            });
        },
    },
};
</script>

<style scoped>
.menu-wrap {
    position: fixed;
    right: 12px;
    bottom: 90px;
    z-index: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 46px;
    height: 46px;
    font-size: 9px;
    border-radius: 46px;
    color: #fff;
    background: #000;
    opacity: 0.6;
    flex-direction: column;
}

.icon {
    width: 16px;
    height: 16px;
}
.directory-icon {
    background: url('../img/icon_directory.png') center/contain no-repeat;
}
.fade-enter-active,
.fade-leave-active {
    transition: all 0.2s ease;
}
.fade-enter,
.fade-leave-to {
    opacity: 0;
}
</style>
