import types from './types';
import { get, post } from '@/modules/request';
import { webview } from '@kk/utils';

export default {
    async checkLogin({ commit }) {
        let checkUserLoginResult = {};
        try {
            checkUserLoginResult = await get({
                url: process.env.VUE_APP_DISTRIBUTION_HOST + '/distribution/user/m_web',
            });
        } catch (error) {
            checkUserLoginResult = error;
        }
        if (checkUserLoginResult.code == 200) {
            const { qrCodeUrl, userId, vipDto = {} } = checkUserLoginResult.data;
            commit(types.SET_USER_INFO, {
                ...checkUserLoginResult.data,
                qrCodeUrl: checkUserLoginResult.host + qrCodeUrl,
                isLogin: true,
                uid: checkUserLoginResult.data.userId,
            });
            localStorage.setItem(`${userId}::vipStatus`, vipDto.vipStatus);
            return Promise.resolve();
        }
        if (checkUserLoginResult.code == 401) {
            commit(types.SET_USER_INFO, {
                isLogin: false,
                uid: -1,
            });
            return Promise.resolve();
        }
        commit(types.SET_USER_INFO, {
            isLogin: false,
            uid: -1,
        });
        return Promise.resolve();
    },
    bindUser({ commit, state }) {
        const linkCps = state.staticInfo.cps.split('_');
        if (linkCps.length < 2) {
            return;
        }
        const linkMarkerId = Number(linkCps[0]) || -1;
        const linkChannelId = Number(linkCps[1]) || -1;
        if (linkMarkerId == -1 || linkChannelId == -1) {
            return;
        }
        const { channelId, marketerId } = state.userInfo;
        if (channelId == linkChannelId && linkMarkerId == marketerId) {
            return;
        }
        return post({
            url: process.env.VUE_APP_DISTRIBUTION_HOST + '/distribution/user/m_web/bind',
            data: {
                marketerId: linkMarkerId,
                channelId: linkChannelId,
            },
        }).then((res) => {
            const { marketerId, channelId } = res.data;
            commit(types.SET_USER_INFO, {
                marketerId,
                channelId,
            });
        });
    },
    getComicInfo({ commit, state }, { comicId, topicId }) {
        const { channelId } = state.userInfo;
        let params = {
            comicId: comicId || '',
            topicId,
            continue: comicId ? false : true,
        };
        if (channelId) {
            params.channelId = channelId;
        }
        return get({
            url: `${process.env.VUE_APP_DISTRIBUTION_HOST}/distribution/comic/detail/v2`,
            data: params,
        }).then((res) => {
            const {
                comic_info: comicInfo,
                next_comic_info: nextComicInfo = {},
                previous_comic_id: previousComicId = -1,
                topic_info,
                rec_topic_first_comic: recTopicFirstComic,
                force_subscribe: forceSubscribe,
            } = res.data;
            let showImages = comicInfo.comic_images || [];
            let comicImages = showImages;
            let recommendInfo = !comicInfo.locked ? recTopicFirstComic : {};
            commit(types.SET_RECOMMEND_INFO, {
                ...recommendInfo,
                // eslint-disable-next-line camelcase
                recommendType: topic_info.update_status_code === 2 ? 'end' : 'continue',
            });
            commit(types.SET_COMIC_INFO, {
                ...comicInfo,
                comicImages,
                nextComicId: nextComicInfo.next_comic_id,
                previousComicId,
                forceSubscribe,
            });
            return res;
        });
    },
    getCurBalance({ commit }) {
        return get({
            url: process.env.VUE_APP_API + '/v2/kb/wallet',
        }).then((res) => {
            const { data } = res;
            let balance = data.wallet.nios_balance;
            if (webview.isApple) {
                balance = data.wallet.ios_balance;
            }
            commit(types.SET_ACCOUNT_BALANCE, balance);
            return balance;
        });
    },
    getHotList({ commit }) {
        return get({
            url: process.env.VUE_APP_DISTRIBUTION_HOST + '/distribution/sales/topic/hot_rec',
        }).then((res) => {
            commit('SET_HOT_LIST', res.data);
            return res.data;
        });
    },
};
