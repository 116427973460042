import Vue from 'vue';
import Vuex from 'vuex';
import mutations from './mutations';
import actions from './actions';
import activity from './activity';
import getters from './getters';

Vue.use(Vuex);
// 从充值页回来，读取缓存数据

const state = {
    userInfo: {
        isLogin: false,
        wxUserInfo: {
            subscribe: undefined,
        },
        uid: '',
        nickname: '',
        avatarUrl: '',
        qrCodeUrl: '',
        channelId: 0,
        marketerId: 0,
    },
    // 首页tab为男生页还是女生页，0-男；1-女
    gender: null,

    comicIdMap: {},
    comicIdList: [], // 后端数据库章节id不是按从小到达的展示顺序， 所以不能依据comicIdMap的key的顺序去确定这个值；
    comicRecordMap: {},
    showingComicId: 0,
    topicInfo: {},
    accountBalance: -1,
    staticInfo: {
        cps: '',
    },
    // 新作推荐
    recommendInfo: {},
    // 热门推荐
    hotComicList: [],
};

export default new Vuex.Store({
    state: state,
    actions: actions,
    mutations: mutations,
    modules: {
        activity,
    },
    getters,
});
