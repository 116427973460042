// 滚动监听mixin
import { throttle } from '@kk/utils';

export const scrollMixin = {
    data() {
        return {
            // 是否正在滚动
            scrollTop: 0,
        };
    },
    mounted() {
        window.addEventListener('scroll', this.handleScroll(), {
            passive: true,
        });
    },
    methods: {
        handleScroll() {
            return throttle(() => {
                const scrollTop = window.pageYOffset || document.body.scrollTop;
                this.showMenu = this.scrollTop - scrollTop > 0;
                this.scrollTop = scrollTop;
            }, 300);
        },
    },
};
