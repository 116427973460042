import Vue from 'vue';
import { get } from '@/modules/request';
import store from '@/store/index';

// https://cn.vuejs.org/v2/api/#Vue-observable
// 最小化的跨组件状态存储器
// Vue.obserbale返回值可直接用于渲染函数和计算属性
const categoryDate = Vue.observable({
    // 排序
    orderType: 'sequent',
    // 漫画目录信息
    comics: [],
    // 漫画更新信息
    updateInfo: {},
    topic_price: 0,
});
const getCategoryList = function (topicId) {
    // 获取漫画目录
    const { channelId } = store.state.userInfo;
    let params = {
        topicId,
    };
    if (channelId) {
        params.channelId = channelId;
    }
    return get({
        url: `${process.env.VUE_APP_DISTRIBUTION_HOST}/distribution/comic/topic_dir`,
        data: params,
    }).then(({ data }) => {
        // 设置漫画更新信息
        categoryDate.updateInfo = data.topic_info;
        // 设置目录信息
        categoryDate.comics = data.topic_info.comics;
        categoryDate.topic_price = data.topic_info.topic_price;
        return data.topic_info;
    });
};
export default {
    categoryDate,
    getCategoryList,
};
