<template>
    <div class="ShareComics" @click.passive="handleTap">
        <div class="header-fixed-wrap">
            <PageHeader :title="showingComicTitle" :showBack="true" @return="backTo" />
        </div>
        <div class="contentPic extra-padding">
            <!-- 图片部分 -->
            <Comic
                v-for="item in comicIdList"
                :key="item"
                :comicId="item"
                @showChargePopup="showChargePopup"
            />
        </div>
        <!-- 漫画推荐 -->
        <ComicRecommend
            v-if="showComicRecommend"
            @update="recommendChange"
            @setRecommendShowStatus="setRecommendShowStatus"
            @beforeShow="doReadComicReport(showingComicId)"
        />
        <!-- 底部菜单栏 -->
        <Menu v-if="readyToShow" :show="showMenu" @update="menuChange" />
    </div>
</template>

<script>
import { query } from '@kk/utils';
import Menu from './components/Menu.vue';
import { scrollMixin } from '@/mixin/scrollMixin.js';
import { mapState, mapGetters } from 'vuex';
import Controller from '@/components/Charge/Controller.js';
import { post } from '@/modules/request.js';
import CateGoryController from '@/views/ComicRead/components/Category/Controller.js';
import ComicRecommend from './components/Recommend/index.vue';
import Comic from './components/comic/index.vue';
import PageHeader from '@/components/header';
import { reportToData } from './ReportToDataGroup.js';
export default {
    name: 'ComicRead',
    mixins: [scrollMixin],
    components: {
        Comic, // 图片展示模块
        // eslint-disable-next-line vue/no-reserved-component-names
        Menu,
        ComicRecommend,
        PageHeader,
    },
    data() {
        return {
            isCreated: false,
            readyToShow: false,
            showMenu: true,
            hasHistory: false,
            triggerPage: '',
            sourceModule: '',
            isShowingRecommend: false,
            payDialog: null,
            onChargePopupShow: false,
        };
    },
    watch: {
        showingComicId(newVal, oldVal) {
            if (!newVal) {
                return;
            }
            if (oldVal) {
                this.doReadComicReport(oldVal);
            }
        },
        topicInfoName(newVal) {
            this.setTitle(newVal);
        },
        isShowingRecommend(newVal) {
            const title = newVal ? this.recommendInfo?.topic_info?.title || '' : this.topicInfoName;
            this.setTitle(title);
        },
    },
    computed: {
        ...mapState([
            'comicIdList',
            'staticInfo',
            'showingComicId',
            'comicIdMap',
            'topicInfo',
            'recommendInfo',
            'userInfo',
            'hotComicList',
            'comicRecordMap',
        ]),
        ...mapGetters(['showingComic']),
        showingComicTitle() {
            return (
                (this.isShowingRecommend
                    ? this.showComicRecommend?.title
                    : this.showingComic?.title) || ''
            );
        },
        topicInfoName() {
            return this.topicInfo?.title || '';
        },
        showComicRecommend() {
            return this.recommendInfo.comic_info;
        },
    },
    deactivated() {
        sessionStorage.setItem('scrollTop', this.scrollTop);
    },
    async created() {
        try {
            await this.comicInfoInit();
            loading.hide();
            this.secondaryReq();
            this.isCreated = true;
        } catch (error) {
            console.error({ error });
            loading.hide();
        }
    },

    // 事件方法
    methods: {
        setRecommendShowStatus(isShowingRecommend) {
            this.isShowingRecommend = isShowingRecommend;
        },
        setTitle(title) {
            if (document.title !== title) {
                document.title = title || '';
            }
        },
        menuChange(comicId) {
            // 点击目录阻断当前阅读 - 上报切换前的漫画进度
            this.doReadComicReport(this.showingComicId);
            // 后续阅读埋点来源 修改为 【目录切换】
            this.sourceModule = '目录切话';
            this.triggerPage = '漫画详细页面';
            this.updateComicInSameRouter({ topicId: this.topicInfo.id, comicId });
        },
        async recommendChange({ topicId, comicId }) {
            // 后续阅读埋点来源 修改为【推荐页】
            this.sourceModule = '推荐页';
            this.triggerPage = '推荐页';
            this.updateComicInSameRouter({ topicId, comicId });
            CateGoryController.getCategoryList(topicId);
        },
        updateComicInSameRouter({ topicId, comicId }) {
            this.$store.commit('RESET_COMIC_READ_INFO');
            return this.$store.dispatch('getComicInfo', { comicId, topicId }).then((res) => {
                const { topic_info = {} } = res.data;
                this.$store.commit('SET_TOPIC_INFO', topic_info);
                this.$store.commit('SET_SHOWING_COMIC_ID', comicId);
                window.scrollTo(0, 0);
                this.$nextTick(() => {
                    loading.hide();
                });
            });
        },
        secondaryReq() {
            return Promise.all([
                CateGoryController.getCategoryList(this.topicInfo.id),
                Controller.getUserPayInfo(),
            ]).then((res) => {
                return res;
            });
        },
        backTo() {
            const count = Number(
                localStorage.getItem(`${this.userInfo.userId}:new-user-count`) || 1
            );
            if (this.userInfo.isLogin && this.userInfo.newUser && count < 20) {
                this.showNewUserPopup(count);
                return;
            }
            if (this.hasHistory) {
                this.$router.back();
                return;
            }
            this.$router.replace({
                name: 'Index',
            });
        },
        async showNewUserPopup(count) {
            if (this.hotComicList.length === 0) {
                const list = await this.$store.dispatch('getHotList');
                if (list.length === 0) {
                    return;
                }
            }
            this.DialogController.syncShow({
                component: () =>
                    import(/* webpackChunkName: "dialog" */ '@/components/popup/newUser/index.vue'),
                props: {
                    withCloseBtn: false,
                    animation: 'slide',
                },
                contentProps: {
                    count,
                    update: async ({ comicId, topicId }) => {
                        // 新用户弹窗阻断当前阅读 - 进行进度上报
                        this.doReadComicReport(this.showingComicId);
                        // 后续阅读埋点来源 修改为【新用户挽留弹窗】
                        this.sourceModule = '新用户挽留弹窗';
                        this.triggerPage = '漫画详细页面';
                        this.updateComicInSameRouter({ comicId, topicId });
                        CateGoryController.getCategoryList(topicId);
                    },
                },
            });
        },
        getComicId() {
            // 返回章节ID优先级
            // 优先返回 router.query 中 -> 路由跳转
            if (this.$route.query && this.$route.query.comicId) {
                return this.$route.query.comicId;
            }
            // 返回阅读记录 historyId
            if (query.get('isContinue') && JSON.parse(query.get('isContinue'))) {
                // 没有comicId的时候会自动启用continue: true
                return '';
            }
            // 返回链接中 chapterId
            return this.staticInfo.comicId;
        },
        showChargePopup(comicId) {
            if (this.onChargePopupShow) {
                return;
            }
            this.onChargePopupShow = true;
            this.payDialog = this.DialogController.syncShow({
                component: () =>
                    import(/* webpackChunkName: "dialog" */ '@/components/popup/pay/Charge.vue'),
                props: {
                    withCloseBtn: false,
                    animation: 'slide',
                },
                contentProps: {
                    comicId: comicId,
                    topicId: this.topicInfo.id,
                    topicPrice: CateGoryController.categoryDate.topic_price,
                },
                events: {
                    afterLeave: () => {
                        this.onChargePopupShow = false;
                        this.payDialog = null;
                    },
                },
            });
        },
        getTopicId() {
            if (this.$route.query && this.$route.query.topicId) {
                return this.$route.query.topicId;
            }
            return this.staticInfo.topicId;
        },
        async comicInfoInit() {
            this.readyToShow = false;
            const topicId = this.getTopicId();
            const comicId = await this.getComicId();
            return this.getComicInfo(comicId, topicId).then(() => {
                this.readyToShow = true;
            });
        },
        async getComicInfo(comicId, topicId, time = 0) {
            if (time > 1) {
                //目标专题下线后，会获取漫画首章id，再重新调用该方法，避免首章下线后导致死循环
                this.$toast.show({
                    text: '内容跑丢了',
                    time: 1500,
                });
                this.$router.replace({
                    name: 'Index',
                });
                return;
            }
            return this.$store
                .dispatch('getComicInfo', { comicId, topicId })
                .then((res) => {
                    // 获取到目标章节
                    const { topic_info = {}, comic_info } = res.data;
                    this.$store.commit('SET_TOPIC_INFO', topic_info);
                    // eslint-disable-next-line camelcase
                    this.$store.commit('SET_SHOWING_COMIC_ID', comic_info.id);
                    return res;
                })
                .catch(async (err) => {
                    // 目标章节已下线 通过章节目录重新获取首章comicId
                    if (err.code === 20802) {
                        const { comics = [] } = await CateGoryController.getCategoryList(topicId);
                        if (comics.length > 0) {
                            this.$toast.show({
                                text: '内容跑丢了，将为您定位到第一话～',
                                time: 1500,
                            });
                            const firstChapter = comics[0];
                            return this.getComicInfo(firstChapter.id, topicId, time++);
                        }
                    }
                    this.$toast.show({
                        text: '内容跑丢了',
                        time: 1500,
                    });
                    this.$router.replace({
                        name: 'Index',
                    });
                });
        },
        handleTap(e) {
            const touchY = e.clientY;
            const partH = window.innerHeight / 3;
            if (touchY > partH && touchY < partH * 2) {
                this.showMenu = !this.showMenu;
            }
        },
        readComicServiceReport(comicId) {
            const { comicImages = [] } = this.comicIdMap[comicId];
            return post({
                url: `${process.env.VUE_APP_DISTRIBUTION_HOST}/distribution/comic/history/report`,
                data: {
                    topicId: this.topicInfo.id,
                    comicId: comicId,
                    totalCount: comicImages.length,
                    readPos: Number.parseInt(
                        comicImages.length * (this.comicRecordMap[comicId] || 0)
                    ),
                },
            }).catch((err) => {
                console.error({ err });
            });
        },
        readComicSaReport(comicId, saParams = {}) {
            const { title = '', is_free = false } = this.comicIdMap[comicId];
            const fixedString = Number(this.comicRecordMap[comicId] || 0).toFixed(1);
            this.sa('ReadComic', {
                TriggerPage: this.triggerPage ? this.triggerPage : '漫画详情页面',
                SourceModule: this.sourceModule ? this.sourceModule : '',
                TopicName: this.topicInfo.topic_name,
                ComicName: title,
                IsPaidTopic: this.topicInfo.free ? 0 : 1,
                // eslint-disable-next-line camelcase
                IsPaidComic: is_free ? 0 : 1,
                IsLogin: true,
                MembershipClassify: 0,
                ComicID: Number(comicId),
                TopicID: Number(this.topicInfo.id),
                uid: this.userInfo.uid,
                ReadPer: Number(fixedString),
                ...saParams,
            });
        },
        readComicDataServiceReport(comicId) {
            const { title = '', is_free = false } = this.comicIdMap[comicId];
            const fixedString = Number(this.comicRecordMap[comicId] || 0).toFixed(1);
            reportToData('ReadComic', {
                TriggerPage: this.triggerPage ? this.triggerPage : '漫画详情页面',
                SourceModule: this.sourceModule ? this.sourceModule : '',
                TopicName: this.topicInfo.topic_name,
                ComicName: title,
                IsPaidTopic: this.topicInfo.free ? 0 : 1,
                // eslint-disable-next-line camelcase
                IsPaidComic: is_free ? 0 : 1,
                ComicID: comicId,
                TopicID: this.topicInfo.id,
                uid: this.userInfo.uid,
                ReadPer: Number(fixedString),
            });
        },
        doReadComicReport(comicId, saParams = {}) {
            if (!this.userInfo.isLogin) {
                return;
            }
            if (comicId && this.comicIdMap[comicId]) {
                // 神策埋点上报
                this.readComicSaReport(comicId, saParams);
                this.readComicDataServiceReport(comicId);
                // 章节已获取 且 阅读进度大于70% 才向服务端上报进度
                if (!this.comicIdMap[comicId].locked && this.comicRecordMap[comicId] >= 0.7) {
                    this.readComicServiceReport(comicId);
                }
            }
        },
    },
    async activated() {
        // 返回之后，检测到弹窗还在，更新充值选项数据
        const payDialogContent = this.payDialog?.$refs?.dialogContent;
        payDialogContent?.getChargeInfo();
        const { comicId = -1, topicId = -1 } = this.$route.query;
        if (!this.isCreated || (comicId === -1 && topicId === -1)) {
            return;
        }
        if (comicId === this.showingComicId) {
            this.setTitle(this.topicInfoName);
            const scrollTop = sessionStorage.getItem('scrollTop');
            sessionStorage.removeItem('scrollTop');
            window.scrollTo(0, Number(scrollTop));
            loading.hide();
            return;
        }
        this.$store.commit('RESET_COMIC_READ_INFO');
        loading.show();
        await this.comicInfoInit();
        loading.hide();
        this.secondaryReq();
    },
    beforeRouteEnter(to, from, next) {
        next((vm) => {
            vm.hasHistory = from.name;
            vm.triggerPage = to.params.TriggerPage || from.meta.trackName;
            vm.sourceModule = to.params.SourceModule;
        });
    },
    beforeRouteLeave(to, from, next) {
        this.doReadComicReport(this.showingComicId);
        next();
    },
};
</script>

<style lang="less" scoped>
@import '~@/assets/vws.less';

.header-fixed-wrap {
    position: fixed;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    z-index: 1;
    .vws(width, 750px);

    background: #fff;
}
.contentPic.extra-padding {
    padding-top: 48px;
}
</style>
<style>
#app {
    position: relative;
}
.ShareComics {
    width: 100%;
    background-color: rgba(240, 240, 240, 1);
}
</style>
