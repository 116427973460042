<template>
    <div class="charge-item" @click="onChoose()">
        <img
            v-if="item.image_info.icon_image"
            :src="item.image_info.icon_image | compress(100, true)"
            class="new-icon"
        />
        <div class="font-medium charge-price">{{ (item.real_price / 100).toFixed(2) }}元</div>
        <div class="charge-num">{{ item.recharge_value }}书币</div>
        <div class="charge-explain">{{ item.words_info && item.words_info.explain_text }}</div>
    </div>
</template>
<script>
export default {
    // eslint-disable-next-line vue/require-prop-types
    props: ['item'],
    methods: {
        onChoose() {
            this.$emit('choose', this.item);
        },
    },
};
</script>
<style lang="less" scoped>
@import '~@/assets/vws.less';

.charge-item {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    .vws(padding-left, 28px);
    .vws(padding-right, 28px);
    .charge-price {
        margin-bottom: -2px;
        padding-right: 2px;
        font-size: 18px;
        font-weight: 500;
        color: #333;
    }
    .charge-num {
        font-size: 16px;
        color: #999;
    }
    .charge-explain {
        display: -webkit-box;
        overflow: hidden;
        font-size: 13px;
        text-align: justify;
        text-overflow: ellipsis;
        color: #f44;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
    }
}
.new-icon {
    position: absolute;
    top: -2px;
    right: -2px;
    .vws(width, 138px);
    .vws(height, 42px);
}
.charge-img {
    .vws(width, 174px);
    .vws(height, 138px);
    // width: 23.2vw;
    // height: 18.4vw;
}

.price-wrap {
    height: 33px;
    font-size: 13px;
    border-top: 1px dashed #eaeaf0;
    color: #f44;
    line-height: 33px;
}
</style>
