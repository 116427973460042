import types from './types';

export default {
    [types.SET_ENTRY_CONFIG](state, entryConfig) {
        Object.assign(state.entryConfig, entryConfig);
    },
    [types.SET_RECHARGE_CONFIG](state, rechargeConfig) {
        Object.assign(state.rechargeConfig, rechargeConfig);
    },
    [types.SET_PURCHASE_NUM](state, num) {
        state.purchaseCount = num;
    },
};
