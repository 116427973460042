<template>
    <div>
        <p class="account-balance">
            剩余
            <span class="balance-num font-medium">{{ accountBalance }}</span>
            书币
        </p>
        <div class="discount-charge">
            <discount-charge-item
                v-for="item in discountRechargeGoods"
                :item="item"
                :key="item.id"
                :PrePage="PrePage"
                class="discount-item"
                :class="{ active: activedGoodId == item.id }"
                @choose="onChoose"
            />
        </div>
        <div class="charge-wrap" key="charge-list">
            <div class="mod-charge" v-if="chargeList.length > 0">
                <p class="charge-text">
                    请选择充值金额
                    <span>（1元=100书币）</span>
                </p>
                <div class="charge-contents">
                    <charge-item
                        v-for="item in chargeList"
                        :key="item.id"
                        :item="item"
                        @choose="onChoose"
                        class="charge-item"
                        :class="{
                            active:
                                activedGoodId == item.id &&
                                activedGoodType === CHARGE_GOOD_TYPE_KKB,
                        }"
                    />
                </div>
            </div>
            <p class="vip-charge-text" v-if="vipRechargeList.length > 0">专属定制读嗨全书城</p>
            <div class="multi-vip-charge-items" v-if="vipRechargeList.length > 0">
                <vip-charge-item
                    v-for="vipRechargeItem in vipRechargeList"
                    :key="vipRechargeItem.id"
                    :vipRecharge="vipRechargeItem"
                    :vipRechargeItemLength="vipRechargeList.length"
                    :PrePage="PrePage"
                    :class="`charge-goods ${vipGoodItemClass} ${
                        activedGoodId == vipRechargeItem.id &&
                        activedGoodType === CHARGE_GOOD_TYPE_VIP &&
                        !curChargeTarget.parentId
                            ? 'active'
                            : ''
                    }`"
                    @choose="onChoose"
                />
            </div>
            <ChargeWay class="mod-charge" :payTypes="payTypes" @change="onChangeChargeType" />
            <rules :topicPrice="topicPrice" />
        </div>
        <div class="charge-btn-wrap" key="charge-btn">
            <div class="font-medium charge-btn" @click="doCharge()">
                立即充值：{{ realPrice }}元
            </div>
        </div>
    </div>
</template>
<script>
import chargeItem from './ChargeItem.vue';
import vipChargeItem from './VipChargeItem.vue';
import discountChargeItem from './DiscountChargeItem.vue';
import ChargeWay from './ChargeWay.vue';
import rules from './rules.vue';
import { mapState } from 'vuex';
import { CHARGE_GOOD_TYPE_VIP, CHARGE_GOOD_TYPE_KKB } from '@/modules/charge.js';

export default {
    components: {
        chargeItem,
        discountChargeItem,
        rules,
        ChargeWay,
        vipChargeItem,
    },
    props: {
        discountRechargeGoods: {
            type: Array,
            default: () => {
                return [];
            },
        },
        vipRechargeList: {
            type: Array,
            default: () => {
                return [];
            },
        },
        chargeList: {
            type: Array,
            default: () => {
                return [];
            },
        },
        curChargeTarget: {
            type: Object,
            default: () => {
                return {};
            },
        },
        // eslint-disable-next-line vue/prop-name-casing
        PrePage: {
            type: String,
            default: '',
        },
        payTypes: {
            type: Array,
            default: () => {
                return [];
            },
        },
        topicPrice: {
            type: Number,
            default: 0,
        },
    },
    data() {
        return {
            btnBottom: ((window.outerHeight - window.innerHeight + 20) / 750) * 100 + 'vw',
            CHARGE_GOOD_TYPE_VIP,
            CHARGE_GOOD_TYPE_KKB,
        };
    },
    computed: {
        ...mapState(['accountBalance']),
        realPrice() {
            if (this.curChargeTarget && this.curChargeTarget.real_price) {
                return (this.curChargeTarget.real_price / 100).toFixed(2);
            }
            return 0;
        },
        activedGoodId() {
            return this.curChargeTarget.id;
        },
        activedGoodType() {
            return this.curChargeTarget.good_type;
        },
        vipGoodItemClass() {
            return this.vipRechargeList.length > 1
                ? this.vipRechargeList.length > 2
                    ? 'multi-vip-recharge-item'
                    : 'two-vip-recharge-item'
                : 'vip-recharge-item';
        },
    },
    methods: {
        onChoose(item) {
            this.$emit('choose', item);
        },
        doCharge() {
            this.$emit('doCharge');
        },
        onChangeChargeType(payType) {
            this.$emit('changePayType', payType);
        },
    },
};
</script>

<style lang="less" scoped>
@import '~@/assets/vws.less';
.account-balance {
    margin-top: 2px;
    font-size: 13px;
    line-height: 17px;
    font-weight: 400;
    text-align: center;
    color: #333;
    .balance-num {
        margin: 0 3px;
        font-size: 13px;
        font-weight: 500;
        color: #f44;
    }
}
.discount-charge {
    margin: 10px 0 5px;
    .discount-item {
        &.active {
            border-color: #f44;
            &::v-deep {
                .discount-item__icon {
                    background: url('./img/select.png') center/100% 100% no-repeat;
                }
                .discount-item__label {
                    right: -2px;
                    padding: 0 10px 0 20px;
                }
            }
        }
    }
}

.mod-charge {
    .vws(padding-left, 32px);
    .vws(padding-right, 32px);
    .charge-text {
        font-size: 14px;
        color: #333;
        line-height: 50px;
        span {
            font-size: 12px;
            color: #f44;
        }
    }
    .charge-contents {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        .charge-item {
            .vws(width, 331px);
            .vws(height, 202px);
            .vws(margin-bottom, 24px);

            border: 2px solid transparent;
            border-radius: 5px;
            background: #f7f9fa;
            box-sizing: border-box;
        }
        .charge-item.active {
            border: 2px solid #ff0745;
        }
        .charge-item:nth-last-child(1) {
            margin-bottom: 0;
        }
        .charge-item:nth-last-child(2) {
            margin-bottom: 0;
        }
    }
}

.charge-btn-wrap {
    position: absolute;
    bottom: calc(2.66vw + constant(safe-area-inset-bottom));
    bottom: calc(2.66vw + env(safe-area-inset-bottom));
    .vws(width, 750px);

    height: 40px;
    box-sizing: border-box;
    .vws(bottom, 20px);
    .vws(padding-left, 32px);
    .vws(padding-right, 32px);
    .charge-btn {
        .vws(width, 686px);

        height: 100%;
        font-size: 16px;
        font-weight: 500;
        border-radius: 22px;
        text-align: center;
        color: #fff4d9;
        background: #f44;
        box-shadow: 0 4px 8px 0 rgba(255, 20, 61, 0.23);
        line-height: 40px;
    }
}

.vip-recharge-item {
    width: 100%;
    background: url('./img/charge-vip-bg.png') bottom right/cover no-repeat;
    background-origin: border-box;
    &.active {
        border-color: #fce07b;
    }
}

.two-vip-recharge-item {
    .vws(width, 331px);
    .vws(margin-right, 24px);

    background-color: #fffae9;
    flex-shrink: 0;
    &.active {
        border-color: #fce07b;
    }
}

.multi-vip-recharge-item {
    .vws(width, 282px);
    .vws(margin-right, 24px);

    background-color: #fffae9;
    flex-shrink: 0;
    &.active {
        border-color: #fce07b;
    }
}
.multi-vip-charge-items {
    display: flex;
    overflow-x: auto;
    margin: 0 16px;
    .vws(margin-top, 24px);
    &::-webkit-scrollbar {
        display: none;
    }
}

.vip-charge-text {
    margin-top: 12px;
    font-size: 14px;
    .vws(padding-left, 32px);
}
</style>
