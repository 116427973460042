<template>
    <div class="page-header android-hack">
        <span class="icon-back" @click="handleReturn" v-if="showBack"></span>
        <p class="title">{{ title }}</p>
        <slot name="right"></slot>
    </div>
</template>

<script>
export default {
    name: 'PageHeader',
    props: {
        title: {
            type: String,
            default: '',
        },
        showBack: {
            type: Boolean,
            default: true,
        },
    },
    data() {
        return {};
    },
    methods: {
        handleReturn() {
            if (this.$listeners['return']) {
                this.$emit('return');
            } else {
                this.$router.go(-1);
            }
        },
    },
};
</script>

<style lang="less" scoped>
.page-header {
    position: relative;
    padding: 0 16px;
    width: 100%;
    height: 48px;
    text-align: center;
    line-height: 48px;
    box-sizing: border-box;

    .icon-back {
        position: absolute;
        top: 12px;
        left: 16px;
        width: 24px;
        height: 24px;
        background: url('./icon-back.png') no-repeat left top / 100% 100%;
    }
    .title {
        font-size: 15px;
        font-weight: 400;
        color: #333;
    }
}
</style>
