<template>
    <div class="vip-item">
        <div v-if="vipRechargeItemLength === 1" class="vip-charge-item" @click="onChoose()">
            <div class="vip-charge-item__line-1">
                <div class="vip-main-name">{{ vipRecharge.name }}</div>
            </div>
            <div class="vip-charge-item__line-2">
                <div class="vip-main-price">
                    <i>¥</i>
                    {{ rechargePrice }}
                </div>
                <div class="origin-price">
                    <del>原价{{ vipRecharge.originPriceStr }}</del>
                </div>
            </div>
            <i
                :class="`vip-charge-label ${
                    vipRecharge.timeLimitedDiscountPrice ? 'discount-label' : 'ordinary-label'
                }`"
            ></i>
        </div>
        <div v-else class="multi-vip-charge-item" @click="onChoose()">
            <div class="vip-main-name">{{ vipRecharge.name }}</div>
            <div class="multi-vip-charge-item__module-2">
                <div class="vip-main-price">
                    <i>¥</i>
                    {{ rechargePrice }}
                </div>
                <div class="origin-price">
                    <del>原价{{ vipRecharge.originPriceStr }}</del>
                </div>
            </div>
            <i
                :class="`vip-charge-label ${
                    vipRecharge.timeLimitedDiscountPrice ? 'discount-label' : 'ordinary-label'
                }`"
            ></i>
        </div>
    </div>
</template>

<script>
export default {
    // eslint-disable-next-line vue/require-prop-types
    props: ['vipRecharge', 'vipRechargeItemLength'],
    computed: {
        rechargePrice() {
            return this.vipRecharge?.realPriceStr;
        },
    },
    methods: {
        onChoose() {
            this.$emit('choose', this.vipRecharge);
        },
    },
};
</script>

<style lang="less" scoped>
@import '~@/assets/vws.less';
.origin-price {
    font-size: 13px;
    font-weight: 400;
    color: #a87f76;
    line-height: 16px;
}
.vip-main-name {
    font-size: 14px;
    font-weight: 500;
    color: #520303;
    line-height: 16px;
}
.vip-main-price {
    font-size: 20px;
    line-height: 24px;
    font-weight: 500;
    color: #520303;
    i {
        font-size: 14px;
        font-style: normal;
    }
}
.vip-charge-label {
    position: absolute;
    top: -2px;
    right: -2px;
    overflow: hidden;
    font-size: 14px;
    text-overflow: ellipsis;
    white-space: nowrap;
    line-height: 28px;
    .vws(height, 56px);
}
.ordinary-label {
    .vws(width, 94px);

    background: url('./img/vip-label.png') left top / 100% no-repeat;
}
.discount-label {
    .vws(width, 146px);

    background: url('./img/limit-time-vip-label.png') left top / 100% no-repeat;
}
.vip-item {
    border: 2px solid transparent;
    box-sizing: border-box;
    .vws(border-radius, 12px);
}
.vip-charge-item {
    position: relative;
    padding: 29px 14px;
    font-size: 0;

    &__line-1 {
        display: flex;
        align-items: center;
    }

    .vip-main-price {
        .vws(margin-right, 12px);

        flex-shrink: 0;
    }

    &__line-2 {
        .vws(margin-top, 14px);

        display: flex;
        align-items: center;
    }
    .vip-charge-desc {
        font-size: 13px;
        line-height: 20px;
        color: #520303;
    }
}

.multi-vip-charge-item {
    position: relative;
    padding: 36px 14px 21px;
    font-size: 0;

    .vip-main-price {
        .vws(margin-top, 8px);
        .vws(margin-right, 8px);
    }
    &__module-2 {
        .vws(margin-top, 8px);

        display: flex;
        align-items: center;
        .vip-main-price {
            margin-top: 0;
        }
    }
    &__module-3 {
        .vws(margin-top, 12px);

        width: fit-content;
    }
}
</style>
