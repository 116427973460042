/* eslint-disable camelcase */
import Vue from 'vue';
import store from '@/store';
import { post, get } from '@/modules/request';
import CateGoryController from '@/views/ComicRead/components/Category/Controller.js';

// https://cn.vuejs.org/v2/api/#Vue-observable
// 最小化的跨组件状态存储器
// Vue.obserbale返回值可直接用于渲染函数和计算属性
const payDate = Vue.observable({
    topicId: 0,
    comicId: 0,
    price: -1,
    totalChargeCnt: 0,
    lastChargeTime: 0,
});

const getUserPayInfo = function () {
    return get({
        url: process.env.VUE_APP_API + '/v2/kb/point/charge_h5',
    }).then((res) => {
        const { charge_values } = res.data;
        payDate.totalChargeCnt = charge_values.total_charge_cnt;
        payDate.lastChargeTime = charge_values.last_charge_time;
        return res;
    });
};
const getPayInfo = function (topicId, comicId) {
    const { channelId } = store.state.userInfo;
    let params = {
        topicId,
        comicId,
    };
    if (channelId) {
        params.channelId = channelId;
    }
    return get({
        url: process.env.VUE_APP_DISTRIBUTION_HOST + '/distribution/sales/payment/comic/price',
        data: params,
    })
        .then((res) => {
            const { price } = res.data;
            payDate.price = price;
            payDate.topicId = topicId;
            payDate.comicId = comicId;
            return res.data;
        })
        .catch(async (err) => {
            if (err.code == 10005) {
                // 商品已购买 -> 重新刷新一次当前漫画
                await store.dispatch('getComicInfo', { topicId, comicId });
            }
            throw err;
        });
};

const payComic = function () {
    const app_data = {
        bd: 'H5',
    };
    const app_info = window.btoa(JSON.stringify(app_data));
    const { topicId, comicId, price } = payDate;
    return post({
        url: process.env.VUE_APP_DISTRIBUTION_HOST + '/distribution/sales/payment/comic/buy',
        headers: {
            'App-Info': app_info,
        },
        data: {
            autoPay: true,
            comicId,
            topicId,
            price,
            cps: store.state.staticInfo.cps,
        },
    }).then(async (res) => {
        store.dispatch('getCurBalance');
        // 购买后更新目录数据
        CateGoryController.getCategoryList(topicId);
        await store.dispatch('getComicInfo', { topicId, comicId });
        return res.data;
    });
};

export default {
    payDate,
    getUserPayInfo,
    payComic,
    getPayInfo,
};
