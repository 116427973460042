import Vue from 'vue';
import store from '@/store';
import { cookie } from '@kk/utils';
import VueRouter from 'vue-router';
import ComicRead from '@/views/ComicRead/index.vue';
import HotList from '@/views/HotList/index.vue';
import Charge from '@/views/Charge/index.vue';
import User from '@/views/User/index.vue';
import DialogController from '@kk/vue-dialog';
Vue.use(VueRouter);

const routes = [
    {
        path: '',
        redirect: 'index',
    },
    {
        path: '/index',
        name: 'Index',
        component: () => import(/* webpackChunkName: "Page-Index" */ '@/views/Index/index.vue'),
        meta: {
            trackName: '首页',
        },
    },
    {
        path: '/comic_read',
        name: 'ComicRead',
        component: ComicRead,
        meta: {
            trackName: '漫画阅读',
        },
    },
    {
        path: '/hot_list',
        name: 'HotList',
        component: HotList,
        meta: {
            trackName: '热门推荐',
            auth: true,
        },
    },
    {
        path: '/charge',
        name: 'Charge',
        component: Charge,
        meta: {
            trackName: '充值中心',
            auth: true,
        },
    },
    {
        path: '/charge_activity',
        name: 'ChargeActivity',
        component: () =>
            import(/* webpackChunkName: "chargeActivity" */ '@/views/ChargeActivity/index.vue'),
        meta: {
            trackName: '充值活动页',
            auth: true,
        },
    },
    {
        path: '/user_info',
        name: 'User',
        component: User,
        meta: {
            trackName: '我的',
            auth: true,
        },
    },
    // {
    //     path: '/checkin',
    //     name: 'Checkin',
    //     component: () => import(/* webpackChunkName: "Checkin" */ '@/views/Checkin/index.vue'),
    //     meta: {
    //         auth: true,
    //     },
    // },
    {
        path: '/customer_service',
        name: 'CustomerService',
        component: () =>
            import(/* webpackChunkName: "CustomerService" */ '@/views/CustomerService/index.vue'),
        meta: { trackName: '用户反馈' },
    },
    {
        path: '/recent_read',
        name: 'RecentRead',
        component: () =>
            import(/* webpackChunkName: "Page-RecentRead" */ '@/views/RecentRead/index.vue'),
        meta: {
            trackName: '最近阅读',
            auth: true,
        },
    },
    {
        path: '/trade_record',
        name: 'TradeRecord',
        component: () =>
            import(
                /* webpackChunkName: "Page-TradeRecord" */ '@/views/TradeRecord/TradeRecord.vue'
            ),
        meta: {
            trackName: '充值记录',
            auth: true,
        },
    },
    {
        path: '/free_activity',
        name: 'FreeActivity',
        component: () =>
            import(/* webpackChunkName: "FreeActivity" */ '@/views/FreeActivity/index.vue'),
        meta: {
            trackName: '限免活动页',
            auth: true,
        },
    },
    {
        path: '/login',
        name: 'Login',
        component: () => import(/* webpackChunkName: "Page-Login" */ '@/views/Login/index.vue'),
        meta: { trackName: '登录' },
    },
    {
        path: '/search',
        name: 'Search',
        component: () => import(/* webpackChunkName: "ChargeMore" */ '@/views/Search/index.vue'),
        meta: { trackName: '搜索页' },
    },
];

const router = new VueRouter({
    routes,
});

router.beforeEach(function (to, from, next) {
    const userInfo = store.state.userInfo;
    if ((!userInfo.isLogin || !cookie.get('passToken')) && to.meta?.auth) {
        next({
            path: '/login',
            query: {
                from: to.fullPath,
            },
        });
    } else {
        if (from && from.name) {
            DialogController.hideAllBy(from.name);
            next();
            return;
        }
        next();
    }
});

router.afterEach((to, from) => {
    if (from && from.name) {
        DialogController.recoveryAllBy(to.name);
    }
});

export default router;
