<template>
    <div class="page-charge" :style="{ height: pageHeight }" :class="{ show: gotData }">
        <div class="charge-wrap">
            <PageHeader title="充值中心" :showBack="showBack" @return="backTo" />
            <ChargeContent
                class="charge-content"
                :chargeList="chargeList"
                :vipRechargeList="vipRechargeList"
                :curChargeTarget="curChargeTarget"
                :discountRechargeGoods="discountRechargeGoods"
                :PrePage="PrePage"
                :payTypes="payTypes"
                @choose="onChoose"
                @changePayType="changePayType"
                @doCharge="doCharge"
            />
        </div>
    </div>
</template>
<script>
import PageHeader from '@/components/header';
import ChargeContent from '@/components/Charge/ChargeContent.vue';
import { get } from '@/modules/request.js';
import { mapState } from 'vuex';
import payController from '@/components/Charge/Controller.js';
import rechargeSelectMixin from '@/mixin/rechargeSelectMixin';
import { ChargeUtils } from '@/modules/charge.js';
export default {
    name: 'Charge',
    mixins: [rechargeSelectMixin],
    components: {
        PageHeader,
        ChargeContent,
    },
    data: function () {
        return {
            gotData: false,
            chargeBasicInfo: {
                comicId: 0,
                topicId: 0,
            },
            PrePage: '',
            pageHeight: window.innerHeight + 'px',
        };
    },
    computed: {
        ...mapState(['showingComicId', 'staticInfo', 'topicInfo', 'accountBalance', 'userInfo']),
        showBack() {
            // history只增不减，无法判断是否存在上一个页面
            return !/setck/.test(document.referrer);
        },
    },
    methods: {
        backTo() {
            this.$router.push({ name: 'User' });
        },
        async doCharge() {
            const { totalChargeCnt, lastChargeTime } = payController.payDate;
            this.sa('ClickRechargeButton', {
                PaymentsAccount: this.curChargeTarget.real_price,
                LastRechargeTime: lastChargeTime,
                RechargeType: totalChargeCnt,
                LatestBalance: this.accountBalance,
            });
            const num = (this.curChargeTarget.real_price / 100).toFixed(2);
            this.sa('CommonBtnClk', {
                uid: this.userInfo.uid,
                CurPage: '充值页',
                ButtonName: `立即充值：${num}元`,
            });
            const userInfo = this.userInfo;
            if (!userInfo.isLogin) {
                this.$router.push({
                    path: '/login',
                });
            }
            this.confirmCharge()
                .then(async () => {
                    const chargeUtils = new ChargeUtils({
                        comicId: this.chargeBasicInfo.comicId, //int 漫画id
                        topicId: this.chargeBasicInfo.topicId, //int 专题id
                        goodId: this.curChargeTarget.id,
                        payType: this.activePayType,
                        goodType: this.curChargeTarget.good_type,
                        goodPrice: this.curChargeTarget.price | this.curChargeTarget.real_price,
                    });
                    try {
                        await chargeUtils.charge();
                    } catch (error) {
                        this.$toast.show('充值失败，请稍后再试～');
                        throw error;
                    }
                })
                .catch((err) => {
                    console.error(err);
                });
        },
        getTopicByComic(comicId) {
            return get({
                url: `${process.env.VUE_APP_DISTRIBUTION_HOST}/distribution/comic/detail`,
                data: {
                    comicId,
                },
            }).then((res) => {
                const data = res.data;
                this.$store.commit('SET_TOPIC_INFO', data.topic_info);
                return data;
            });
        },
        async getChargeUploadInfo() {
            const comicId = this.showingComicId || this.staticInfo.comicId;
            const topicId = this.topicInfo.id || this.staticInfo.topicId;
            if (comicId && topicId) {
                this.chargeBasicInfo = {
                    comicId,
                    topicId: topicId,
                };
                return;
            }
            // 直接进入充值中心 且 仅带有comicId
            if (this.staticInfo.comicId) {
                const { topicInfo } = await this.getTopicByComic(this.staticInfo.comicId);
                this.chargeBasicInfo = {
                    comicId: this.staticInfo.comicId,
                    topicId: topicInfo.id,
                };
            }
        },
    },
    async created() {
        document.title = '充值中心';
        loading.show();
        this.gotData = false;
        await this.getChargeInfo();
        if (this.chargeList.length) {
            this.gotData = true;
        }
        this.getChargeUploadInfo();
        await payController.getUserPayInfo();
        const { totalChargeCnt, lastChargeTime } = payController.payDate;
        this.sa('VisitCheckout', {
            LastRechargeTime: lastChargeTime,
            RechargeType: totalChargeCnt,
            LatestBalance: this.accountBalance,
        });
    },
    beforeRouteEnter(to, from, next) {
        next((vm) => {
            const routeArr = [
                { name: 'User', label: '我的' },
                { name: 'ComicRead', label: '阅读页' },
            ];
            const lastRoute = routeArr.find((item) => item.name === from.name) || {};
            if (lastRoute.name) vm.PrePage = lastRoute.label;
        });
    },
    mounted() {
        this.sa('CommonPageOpen', {
            uid: this.userInfo.uid,
            CurPage: '充值页',
            PrePage: this.PrePage,
        });
    },
};
</script>
<style lang="less" scoped>
@import '~@/assets/vws.less';

.page-charge {
    position: relative;
    height: 100%;
    background: #fff;
    opacity: 0;
}
.charge-wrap {
    overflow-y: scroll;
    height: calc(100% - 70px);
}

.charge-wrap::-webkit-scrollbar {
    display: none;
}

/* 加载数据时的动画效果 */
.page-charge.show {
    animation: 0.3s opcityShow forwards ease-in;
}
@keyframes opcityShow {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

.charge-content::v-deep {
    /* 余额区域 */
    .mod-balance {
        .desc {
            color: #fff;
        }
        .balance-num {
            color: #ffe8ab;
        }
    }
    .discount-charge {
        margin: 20px 0 5px;
    }
    .charge-btn-wrap {
        position: fixed;
    }
}
</style>
