import router from '@/router/index.js';

let onRequest = false;
/**
 * 分销首页通用跳转
 * @param {Object} actionType 通用跳转对象
 * public interface ActionType {
    无跳转
    int NONE = 3001;
    续读漫画专题跳转
    int CONTINUE_READ = 3002;
    跳转到分类页
    int HYBRID = 3003;
    跳转下发的链接
    int CATEGORY = 3004;
    跳转到阅读历史页(2B分销使用)
    int READING_HISTORY = 3006;
    跳转到我的页面(2B分销使用)
    int MINE = 3007;
    }
 */

async function jump(
    actionType,
    { params = {}, query = {} } = {},
    beforeJumpPromise = Promise.resolve()
) {
    if (onRequest) {
        return;
    }
    await beforeJumpPromise;
    const targetType = Number(actionType.type);
    switch (targetType) {
        case 3002: {
            router.push({
                name: 'ComicRead',
                query: { ...query, topicId: actionType.target_id },
                params: params,
            });
            break;
        }
        case 3003: {
            window.location.href = actionType.target_web_url;
            break;
        }
        case 3006: {
            // 最近阅读
            router.push({
                name: 'RecentRead',
                query,
                params,
            });
            break;
        }
        case 3007: {
            // 我的
            router.push({
                name: 'User',
                query,
                params,
            });
            break;
        }
    }
}

export default jump;
