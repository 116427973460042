<template>
    <div class="discount-item" @click="onChoose(item)" v-if="couponInfo">
        <div class="discount-item__line-1">
            <span class="discount-item__money">{{ couponInfo.coupon_desc1 }}</span>
            <span class="discount-item__kkb">{{ couponInfo.coupon_desc2 }}</span>
        </div>
        <div class="discount-item__line-2">
            <span class="discount-item__icon"></span>
            <span class="discount-item__text">{{ couponInfo.coupon_desc3 }}</span>
        </div>
        <div class="discount-item__label">
            {{ couponInfo.coupon_title }}
        </div>
    </div>
</template>

<script>
export default {
    // eslint-disable-next-line vue/prop-name-casing, vue/require-prop-types
    props: ['item', 'PrePage'],
    computed: {
        couponInfo() {
            return this.item?.first_recharge_coupon;
        },
    },
    methods: {
        onChoose() {
            this.$emit('choose', this.item);
        },
    },
    mounted() {
        const { couponInfo } = this;
        if (couponInfo) {
            this.sa('CommonItemImp', {
                ContentName: `${couponInfo.coupon_desc1}充值优惠`,
                ClkItemType: '充值',
                TabModuleType: '充值活动模块',
                TabModuleTitle: '充值活动',
                PrePage: this.PrePage,
                CurPage: '充值页',
            });
        }
    },
};
</script>

<style lang="less" scoped>
@import '~@/assets/vws.less';
.discount-item {
    position: relative;
    box-sizing: border-box;
    .vws(width, 686px);

    margin: 0 auto 5px;
    .vws(padding-top, 56px);
    .vws(padding-bottom, 56px);
    .vws(padding-left, 28px);
    .vws(padding-right, 28px);
    .vws(border-radius, 12px);

    font-size: 0;
    border: 2px solid transparent;
    background: url('./img/charge-coupon-bg.png') bottom right/cover no-repeat;

    &__line-1 {
        display: flex;
        align-items: flex-end;
        margin-bottom: 8px;
    }

    &__line-2 {
        display: flex;
        align-items: center;
    }

    &__money {
        margin-right: 12px;
        font-size: 18px;
        font-weight: 500;
        color: #520303;
        line-height: 22px;
        flex-shrink: 0;
    }
    &__kkb {
        font-size: 14px;
        line-height: 18px;
        color: #520303;
        opacity: 0.5;
    }
    &__icon {
        display: inline-block;
        margin-right: 5px;
        width: 15px;
        height: 15px;
        background: url('./img/no-select.png') center/100% 100% no-repeat;
    }
    &__text {
        font-size: 12px;
        line-height: 15px;
        color: #520303;
    }
    &__label {
        position: absolute;
        top: -2px;
        right: 0;
        overflow: hidden;
        padding: 0 8px 0 20px;
        font-size: 14px;
        font-weight: 600;
        text-overflow: ellipsis;
        white-space: nowrap;
        color: #fff;
        background: url('./img/lable-bg.png') center/100% 100% no-repeat;
        line-height: 28px;
        .vws(border-top-right-radius, 12px);
        .vws(max-width, 176px);
    }
}
</style>
