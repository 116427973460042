/**
 *  活动相关
 */

import mutations from './mutations';
import getters from './getters';
import actions from './actions';

const state = {
    // 入口信息
    entryConfig: {
        popImg: '',
    },
    // 充值活动信息
    rechargeConfig: {
        purchaseLimit: 0,
        activityImg: '',
    },
    // 交易次数
    purchaseCount: 0,
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
