const storageKey = `to-business-wap-teenager`;
const canShowTeenagerPopup = () => {
    if (localStorage) {
        const shown = localStorage.getItem(storageKey);
        return !shown;
    }
    return false;
};

const showTeenagerPopup = (popupShowFunction) => {
    const popupIns = popupShowFunction({
        component: () => import(/* webpackChunkName: "dialog" */ '@/components/Teenager/Popup.vue'),
        props: {
            modal: true,
            withCloseBtn: false,
        },
        contentProps: {
            storageKey,
            closeCallback: (value) => {
                localStorage.setItem(storageKey, value);
                popupIns.onCloseTap();
            },
        },
    });
};

export { storageKey, canShowTeenagerPopup, showTeenagerPopup };
