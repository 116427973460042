<template>
    <div id="app" ref="app">
        <keep-alive :include="keepAliveList">
            <router-view />
        </keep-alive>
        <div v-if="showClearBtn" class="clear-cookie-btn" @click="clearCookie()">清</div>
    </div>
</template>
<script>
import { mapState, mapGetters } from 'vuex';
import { removeCookie } from '@/modules/tools.js';
import { query } from '@kk/utils';
import { get } from '@/modules/request.js';
import load from '@kk/load';
import { canShowTeenagerPopup, showTeenagerPopup } from '@/components/Teenager/Controller.js';

// 页面与弹窗位置映射
const RECHARGE_POS_MAP = {
    // 首页
    '/index': 1,
    // 最近阅读
    '/recent_read': 2,
    // 个人中心
    '/user_info': 3,
};

export default {
    name: 'App',
    data() {
        const fromH5ChargeBack = Number(sessionStorage.getItem('kk-recharge-activity') || 0);
        const fromMiniChargeBack = Number(query.get('activityId') || 0) && query.get('order_id');
        return {
            isFromRechargeActivity: Boolean(fromH5ChargeBack || fromMiniChargeBack),
            showRechargePopup: false,
            keepAliveList: ['ComicRead', 'Index', 'FreeActivity'],
        };
    },
    computed: {
        ...mapState(['userInfo']),
        ...mapGetters('activity', ['canJoinAct']),
        ...mapState('activity', ['rechargeConfig', 'entryConfig', 'purchaseCount']),
        showClearBtn() {
            return process.env.VUE_APP_HOST_ENV !== 'production' && !query.get('noClearBtn');
        },
        vipInfo() {
            const { vipInfo = {} } = this.userInfo;
            return vipInfo;
        },
    },
    watch: {
        '$route.path': {
            handler(val) {
                if (this.userInfo.uid < 0) return;
                !this.isFromRechargeActivity && this.judgeShowPopup(val);
            },
            immediate: true,
        },
    },
    async created() {
        if (canShowTeenagerPopup()) {
            showTeenagerPopup(this.DialogController.syncShow);
        }
        const uid = this.userInfo.uid;
        if (uid < 0) {
            return;
        }
        const lastSaTime = localStorage.getItem(`${uid}-to-business`);
        const DAY_TO_MS = 24 * 60 * 60 * 1000;
        const isToday =
            new Date(lastSaTime).getDate() == new Date().getDate() &&
            Date.now() - lastSaTime < DAY_TO_MS;
        localStorage.setItem(`${uid}-to-business`, Date.now());
        this.sa('OpenProgram', {
            FirstOpen: isToday ? 1 : 0,
            ProgramOpenWay: '其他',
            Channels: '',
            ChannelsType: '0',
            // 当前投放渠道只有微信环境
            OpenBelongPlat: '微信',
            IsLogin: true,
        });

        if (this.isFromRechargeActivity) {
            this.afterPayFromActivity();
        } else if (this.userInfo.IsLogin) {
            this.$store.dispatch('activity/getRechargeActivityInfo').then(() => {
                this.judgeShowPopup();
            });
        }
    },
    methods: {
        async checkOrderStatus() {
            try {
                const { data = {} } = await this.getOrderDetail();
                if (data?.pay_order.pay_status === 2) {
                    this.$toast.show('支付成功，请返回最近阅读页面继续阅读');
                    await this.$store.dispatch('activity/updatePurchaseLimitNum');
                    await this.$store.dispatch('activity/getRechargeActivityInfo');
                    this.judgeShowPopup();
                    return;
                }

                this.$toast.show('充值失败');
            } catch (err) {
                console.error(err.message);
                this.$toast.show(err.message || '服务器开小差啦，请稍后再试！');
            }
        },
        getOrderDetail() {
            return get({
                url: process.env.VUE_APP_API + '/v1/pay/pay_order/query/detail_order_id_recharge',
                data: {
                    // 标识分销活动特有参数
                    order_id: query.get('order_id'),
                    env: 's',
                },
            });
        },
        afterPayFromActivity() {
            // 在从活动充值页回来时，需要进行查单操作
            const orderId = query.get('order_id');

            if (!orderId) return;

            // 充值标记位
            this.$store.commit('activity/SET_RECHARGE_CONFIG', {
                activityId: sessionStorage.getItem('kk-recharge-activity'),
            });
            this.isFromRechargeActivity = false;
            this.checkOrderStatus();
        },
        judgeShowPopup(val = this.$route.path) {
            // 判断是否展示充值活动弹窗
            // 弹窗展示条件：每个页面，每天展示一次

            // 不可参加活动，不弹弹窗
            if (!this.entryConfig?.popStatus || !this.canJoinAct || this.showRechargePopup) return;

            const key = `kk-distribution-recharge-popup-${this.userInfo.uid}`;
            // 当前页面是否需要展示
            const needShow = (this.entryConfig?.popPosList || []).includes(RECHARGE_POS_MAP[val]);
            // 判断当前页面是否在今日首次展示
            const temp = localStorage.getItem(key);
            const showConfigObj = JSON.parse(temp || '{}');
            const today = new Date().toDateString();
            const last = new Date(parseInt(showConfigObj[val] || 0)).toDateString();
            const isFristShow = today !== last;

            if (needShow && isFristShow) {
                const newObj = Object.assign(showConfigObj, {
                    [val]: Date.now(),
                });
                localStorage.setItem(key, JSON.stringify(newObj));
                this.openRechargePopup();
            }
        },
        checkVipExpire() {
            const { vipStatus } = this.vipInfo;
            // vip 已过期
            if (vipStatus === 2) {
                const { userId } = this.userInfo;
                const cacheStatus = localStorage.getItem(`${userId}::vipStatus`);
                // 离线状态下过期，进入页面展示过期弹窗
                if (cacheStatus === 1) {
                    this.showVipExpirePopup();
                }
            }
        },
        showVipExpirePopup() {
            const dialog = this.DialogController.asyncShow({
                component: () =>
                    import(/* webpackChunkName: "dialog" */ '@/components/popup/confirm/index.vue'),
                contentProps: {
                    title: '温馨提示',
                    message: '您的VIP已到期，若继续享受VIP免费看权益，请续费！',
                    showCancle: false,
                    confirmBtn: '续费',
                    actionFn: (action) => {
                        if (action === 'confirm') {
                            this.$router.push({
                                path: '/charge',
                            });
                        }
                        dialog.close();
                    },
                },
            });
        },
        openRechargePopup() {
            this.showRechargePopup = true;

            load([this.$options.filters.compress(this.entryConfig.popImg, 650, true)], 5000).then(
                () => {
                    // 显示充值弹窗
                    this.DialogController.syncShow({
                        component: () =>
                            import(
                                /* webpackChunkName: "dialog" */ '@/components/popup/chargeActivityPopup/index.vue'
                            ),
                        events: {
                            afterLeave: () => (this.showRechargePopup = false),
                        },
                    });
                }
            );
        },
        clearCookie() {
            removeCookie('passToken');
            removeCookie('oid');
            removeCookie('kk_s_t');
        },
    },
};
</script>
<style lang="less">
ul,
li {
    margin: 0;
    padding: 0;
}
li {
    list-style: none;
}
.font-medium {
    font-family: PingFangSC-Medium;
    font-weight: 700;
}
.line-limit {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    word-break: break-all;
}
.clear-cookie-btn {
    position: fixed;
    bottom: 4vh;
    left: 20px;
    z-index: 1000;
    width: 30px;
    height: 30px;
    font-size: 16px;
    border-radius: 15px;
    text-align: center;
    color: #fff;
    background: red;
    line-height: 30px;
}
.isAndroid .android-hack {
    padding-top: 1px !important;
}

.ell {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

@media screen and (min-width: 750px) {
    html,
    body {
        font-size: 20px;
        background: #ccc;
    }
    #app {
        margin: 0 auto;
        width: 375px;
        background: #fff;
    }
}
</style>
