<template>
    <div class="contentPic" ref="comic" id="totalComics">
        <!-- 图片部分 高度根据图片进行计算 -->
        <div class="ComicPicsBox">
            <ComicPics :comicId="comicId" class="content" />
        </div>
    </div>
</template>
<script>
import ComicPics from '@/components/ComicPics.vue';
import { mapState } from 'vuex';
import types from '@/store/types';
import payController from '@/components/Charge/Controller.js';

export default {
    components: {
        ComicPics, // 图片展示模块
    },
    // eslint-disable-next-line vue/require-prop-types
    props: ['comicId'],
    data: function () {
        return {
            // 本章漫画上边缘至body的距离
            offsetTop: 0,
            height: 0,
            gettingNextComic: false,
            clientHeight: screen.height,
            onBuyComic: false,
            followShown: false,
        };
    },
    watch: {
        curComicInfoImgLength(newVal, oldVal) {
            if (newVal > oldVal) {
                this.$nextTick(() => {
                    this.setCurComicHeight();
                });
            }
        },
        scrollTop(newVal, oldVal) {
            if (this.curRouteName !== 'ComicRead') {
                return;
            }
            // 本章漫画已浏览的距离 = 滚动距离 - 上边缘 + 屏幕高度 + 新作推荐
            const read = newVal - this.offsetTop + this.clientHeight + this.recommendWrapHeight;
            // 在发生路由切换时，scrollTop会变为0，导致 comicId 发生变化；故需要在代码中对这种情况进行处理
            if (read >= 0 && read <= this.height && this.showingComicId != this.comicId) {
                this.onChangeComic();
            }
            // 以下逻辑均针对当前展示的漫画内容 且 均在向下滚动中处理
            if (this.comicId != this.showingComicId || oldVal > newVal) {
                return;
            }
            //  是否已经触底 = 滚动距离 < 屏幕高度 - 10px
            const isToBottom = this.height - read <= 10;
            if (isToBottom) {
                this.toCurComicBottomHandler();
            }
            if (this.staticInfo.type == 2) {
                // 外推链接所有漫画内容一次性已获取 不再进行滚动请求
                return;
            }
            const readRatio = read / this.height;
            this.$store.commit('SET_COMIC_RECORD', {
                comicId: this.comicId,
                readRatio,
            });
            if (readRatio > 0.7 && !this.gettingNextComic) {
                this.getNextComic();
            }
        },
    },
    computed: {
        ...mapState([
            'accountBalance',
            'comicIdMap',
            'comicIdList',
            'topicInfo',
            'staticInfo',
            'showingComicId',
            'userInfo',
            'recommendInfo',
        ]),
        curComicInfo() {
            return this.comicIdMap[this.comicId] || {};
        },
        curComicInfoImgLength() {
            return this.curComicInfo.comicImages ? this.curComicInfo.comicImages.length : 0;
        },
        scrollTop() {
            return this.$parent.scrollTop;
        },
        recommendWrapHeight() {
            if (!this.recommendInfo.comic_info) {
                return 0;
            }
            const dom = document.querySelector('#recommendWrap');
            return dom.offsetHeight;
        },
        curRouteName() {
            return this.$route.name;
        },
    },
    mounted() {
        this.offsetTop = this.$refs.comic.offsetTop;
        this.setCurComicHeight();
    },
    methods: {
        toCurComicBottomHandler() {
            // 如果当前章节为付费章节且未付费 则展示付费弹窗
            if (this.curComicInfo.locked) {
                this.onBottomPay();
                return;
            }
            // 已阅读所有章节
            if (!this.curComicInfo.nextComicId || this.curComicInfo.nextComicId <= 0) {
                return;
            }
            if (this.gettingNextComic) {
                // 在滚动中 请求漫画数据 但滚动至页面底部仍未获取到信息
                loading.show();
            }
        },
        getNextComic() {
            const { nextComicId, locked } = this.curComicInfo;
            if (!nextComicId || nextComicId < 0) {
                return;
            }
            if (locked) {
                return;
            }
            // 已经获取过章节信息就不在请求数据
            if (this.comicIdMap[nextComicId]) {
                return;
            }
            this.gettingNextComic = true;
            return this.$store
                .dispatch('getComicInfo', { comicId: nextComicId, topicId: this.topicInfo.id })
                .then(() => {
                    loading.hide();
                    return (this.gettingNextComic = false);
                });
        },
        async buyComic() {
            if (this.onBuyComic) {
                return;
            }
            // 进入新章节阅读页面 & 账号余额充足 => 直接购买
            try {
                this.onBuyComic = true;
                const payResult = await payController.payComic();
                this.onBuyComic = false;
                const payDate = payController.payDate;
                this.sa('Consume', {
                    AutoPaid: true,
                    CurrentPrice: payDate.price,
                    SpendGiveCurrency: payResult.activityKkb || 0,
                    SpendRecharge: payResult.chargedKkb || 0,
                    ComicName: this.curComicInfo.title,
                    TopicName: this.topicInfo.topic_name,
                    ComicID: this.curComicInfo.id,
                    TopicID: this.topicInfo.id,
                    TriggerPage: '漫画阅读页',
                });
            } catch (e) {
                console.error(e);
                this.onBuyComic = false;
                this.$toast.show(e.message || '购买失败');
            }
        },
        showChargePopup() {
            this.$emit('showChargePopup', this.comicId);
        },
        getChargeInfo() {
            const beforeShowPopupPromiseList = [];
            const { comicid } = payController.payDate;
            if (comicid != this.comicId) {
                beforeShowPopupPromiseList.push(
                    payController.getPayInfo(this.topicInfo.id, this.comicId)
                );
            }
            if (this.accountBalance < 0) {
                beforeShowPopupPromiseList.push(this.$store.dispatch('getCurBalance'));
            }
            return Promise.all(beforeShowPopupPromiseList);
        },
        async onBottomPay() {
            try {
                loading.show();
                if (this.userInfo.isLogin) {
                    await this.getChargeInfo();
                    const payDate = payController.payDate;
                    if (payDate.price <= this.accountBalance) {
                        // 书币足够则自动购买
                        await this.buyComic();
                        loading.hide();
                    } else {
                        // 余额不足，去充值
                        loading.hide();
                        this.showChargePopup();
                    }
                } else {
                    // 没有登录直接显示充值半屏
                    loading.hide();
                    this.showChargePopup();
                }
            } catch (e) {
                loading.hide();
                console.error(e);
                this.$toast.show(e.message || '购买失败');
            }
        },
        async onChangeComic() {
            this.$store.commit(types.SET_SHOWING_COMIC_ID, this.comicId);
            // 切换漫画后 如果为付费漫画 && 余额充足 直接进行购买
            if (this.curComicInfo.locked) {
                await this.getChargeInfo();
                const payDate = payController.payDate;
                if (payDate.price <= this.accountBalance) {
                    await this.buyComic();
                }
            }
        },
        setCurComicHeight() {
            this.height = this.$refs.comic.offsetHeight;
        },
    },
};
</script>
<style scoped>
.contentPic {
    min-height: 100.1vh;
}
</style>
