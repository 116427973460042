import Vue from 'vue';
import App from './App.vue';
import store from './store';
import globalData from './global';
import router from './router';
import lazyload from '@kk/vue-lazy';
import tap from '@kk/tap';
import toast from '@kk/toast';
import * as Integrations from '@sentry/integrations';
import load from '@kk/load';
import DialogController from '@kk/vue-dialog';
import { query, compress } from '@kk/utils';
import { formatDate } from '@/modules/tools.js';
import config from '@/modules/config.js';

// 接入 sentry
if (process.env.VUE_APP_HOST_ENV !== 'development') {
    Sentry.init({
        dsn: process.env.VUE_APP_SENTRY_DSN,
        integrations: [new Integrations.Vue({ Vue, attachProps: true })],
        ignoreErrors: ['Promise', 'xhr error:0', '需要登录', '未登录'],
    });
}

lazyload.setDefaultBuffer(667);

tap(document.body);
Vue.use(lazyload);
Vue.prototype.DialogController = DialogController;
Vue.prototype.$toast = toast;
Vue.config.productionTip = false;

Vue.mixin({
    data: () => globalData,
    filters: {
        compress,
        formatDate,
    },
    methods: {
        sa(eventName, param) {
            console.info(eventName, param);
            // 暂时先不做埋点上报，从源头切断上报
            // const cps = query.get('cps') || '';
            // const cpsList = cps.split('_');
            // const {newUser = false, channelName = ''} = store.state.userInfo;
            // kksa.track(eventName, {
            //   H5Page: location.href,
            //   SourcePlatform: '分销M站',
            //   cps_parameter: cps,
            //   SubscriptionId: cpsList[0] + '_' + cpsList[1],
            //   IsNew: newUser,
            //   SubscriptionName: channelName,
            //   ...param
            // });
        },
        loadImg(url, w = 700, isAlpha = true) {
            let bg = compress(url, w, isAlpha);
            return load([bg], 5000)
                .then(() => {
                    return bg;
                })
                .catch(() => {
                    return bg;
                });
        },
        removeBodyScroll() {
            document.body.classList.add('scroll-ignore');
        },
        addBodyScroll() {
            document.body.classList.remove('scroll-ignore');
        },
    },
});

function getLinkQuery() {
    // 获取链接参数
    return {
        cps: query.get('cps') || '',
        appId:
            process.env.VUE_APP_HOST_ENV == 'development'
                ? query.get('appId') || 'wx2d7151ee1c1383dd'
                : document.domain.split('.')[0],
        // 内外推链接ID
        id: query.get('id'),
        // 阅读漫画章节ID
        comicId: query.get('comicId') || '',
        topicId: query.get('topicId') || '',
    };
}

async function init() {
    const linkConf = getLinkQuery();
    store.commit('SET_LINK_STATIC_INFO', linkConf);
    if (process.env.NODE_ENV === 'development') {
        // 本地开发，需要到测试环境拷贝cookie
        document.cookie = `passToken=${config.DEV_PASSTOKEN}`;
    }
    await store.dispatch('checkLogin');
    if (store.state.userInfo.isLogin) {
        store.dispatch('bindUser');
    }
    // kksa.identify(uid);

    new Vue({
        router,
        store,
        created() {
            this.DialogController.bind(this);
        },
        render: (h) => h(App),
    }).$mount('#app');
}

init();
