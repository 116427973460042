// 获取随机数
const getRndNum = (n) => {
    var rnd = '';
    for (var i = 0; i < n; i++) {
        rnd += Math.floor(Math.random() * 10);
    }
    return rnd;
};

function padLeftZero(str) {
    return ('00' + str).substr(str.length);
}

// 时间格式化
function formatDate(value, format = '') {
    let date = new Date(value);
    let nowDate = new Date();
    if (/(y+)/.test(format)) {
        format = format.replace(RegExp.$1, (date.getFullYear() + '').substr(4 - RegExp.$1.length));
    }
    let o = {
        'M+': date.getMonth() + 1,
        'd+': date.getDate(),
        'h+': date.getHours(),
        'm+': date.getMinutes(),
        's+': date.getSeconds(),
    };
    if (
        nowDate.getFullYear() === date.getFullYear() &&
        nowDate.getMonth() === date.getMonth() &&
        nowDate.getDate() === date.getDate() &&
        new RegExp('today').test(format)
    ) {
        return '今天';
    }
    format = format.replace(/today/, '');
    for (let k in o) {
        if (new RegExp(`(${k})`).test(format)) {
            let str = o[k] + '';
            format = format.replace(RegExp.$1, RegExp.$1.length === 1 ? str : padLeftZero(str));
        }
    }
    return format;
}

// 动态操作url 不刷新页面
function setUrl(url = '', tilte = '') {
    let isExist = window.history.pushState;
    if (!url || typeof url != 'string') {
        return false;
    }
    let titleEle = document.getElementsByTagName('title')[0]
        ? document.getElementsByTagName('title')[0]
        : {};
    tilte = tilte ? tilte : titleEle.innerHTML ? titleEle.innerHTML : '快看漫画';
    if (!isExist) {
        // 浏览器不支持的情况下 什么都不做
        window.history.pushState = function (url) {
            console.warn('请升级浏览器,体验更流畅~');
            window.location.href = url;
        };
        window.history.pushState(url);
    } else {
        window.history.pushState(null, null, url); // 动态修改url
        titleEle.innerHTML = tilte;
    }
}

// 因passToken种到子域、oid种到主域，补充cookie清除的方法 保证对应cookie一定被移除。
function removeCookie(name) {
    document.cookie = `${name}=;max-age=-1;`;
    document.cookie = `${name}=;max-age=-1;path=/;`;
    // stag wx286351e887652c9a.stag.kkj2.com
    document.cookie = `${name}=;max-age=-1;path=/;domain=${location.host}`;
    // stag stag.kkj2.com
    document.cookie = `${name}=;max-age=-1;path=/;domain=${location.host
        .split('.')
        .slice(-3)
        .join('.')}`;
    // stag .stag.kkj2.com
    document.cookie = `${name}=;max-age=-1;path=/;domain=.${location.host
        .split('.')
        .slice(-3)
        .join('.')}`;
    // stag .kkj2.com
    document.cookie = `${name}=;max-age=-1;path=/;domain=.${location.host
        .split('.')
        .slice(-2)
        .join('.')}`;
}

// 对象转化为query
// urlEncode({name:'tom',age:18}, 'pre') 输出: &pre.name=tom&pre.age=18
function urlEncode(param, key, encode) {
    if (param == null) return '';

    let paramStr = '';
    const t = typeof param;
    if (t == 'string' || t == 'number' || t == 'boolean') {
        paramStr +=
            '&' + key + '=' + (encode == null || encode ? encodeURIComponent(param) : param);
        return paramStr;
    }
    for (let i in param) {
        let k = key == null ? i : key + (param instanceof Array ? '[' + i + ']' : '.' + i);
        paramStr += urlEncode(param[i], k, encode);
    }
    return paramStr;
}

export { getRndNum, formatDate, setUrl, removeCookie, urlEncode };
