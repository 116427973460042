import types from './types';

export default {
    [types.SET_USER_INFO](state, userInfo) {
        state.userInfo = userInfo;
    },
    [types.SET_GENDER](state, gender) {
        state.gender = gender;
    },
    [types.SET_OUTSIDE_LINK_COMICS](state, comics) {
        state.comicIdList = comics.map((item) => item.comicId);
        for (let i = 0; i < comics.length; i++) {
            let previousComicId = i > 0 ? comics[i - 1].comicId : -1;
            let nextComicId = i < comics.length - 1 ? comics[i + 1].comicId : -1;
            state.comicIdMap[comics[i].comicId] = {
                ...comics[i],
                title: comics[i].comicName,
                comicImages: comics[i].images,
                previousComicId,
                nextComicId,
            };
        }
    },
    [types.SET_TOPIC_INFO](state, topicInfo) {
        state.topicInfo = Object.assign({}, state.topicInfo, topicInfo);
    },
    [types.SET_COMIC_INFO](state, comicInfo) {
        // 已经获取过
        if (state.comicIdMap[comicInfo.id]) {
            state.comicIdMap[comicInfo.id] = comicInfo;
            state.comicIdMap = Object.assign({}, state.comicIdMap, {});
            return;
        }
        state.comicIdMap[comicInfo.id] = comicInfo;
        state.comicIdMap = Object.assign({}, state.comicIdMap, {});
        const temp = state.comicIdList.map((item) => item);
        temp.push(comicInfo.id);
        state.comicIdList = temp;
    },
    [types.SET_SHOWING_COMIC_ID](state, comicId) {
        state.showingComicId = comicId;
    },
    [types.SET_ACCOUNT_BALANCE](state, balance) {
        state.accountBalance = balance;
    },
    [types.SET_LINK_STATIC_INFO](state, staticInfo) {
        Object.assign(state.staticInfo, staticInfo);
    },
    [types.RESET_COMIC_READ_INFO](state) {
        state.comicIdMap = {};
        state.comicIdList = [];
        state.showingComicId = 0;
        state.topicInfo = {};
        state.comicRecordMap = {};
    },
    [types.SET_RECOMMEND_INFO](state, info) {
        info.topicAliasOrName = info.topicAlias || info.topicName;
        state.recommendInfo = info;
    },
    [types.SET_HOT_LIST](state, comicList) {
        comicList = comicList.map((item) => {
            item.topicAliasOrName = item.topicAlias || item.topicName;
            return item;
        });
        state.hotComicList = comicList;
    },
    [types.SET_COMIC_RECORD](state, { comicId, readRatio }) {
        let ratio = readRatio;
        if (ratio > 1) {
            ratio = 1;
        } else if (ratio < 0) {
            ratio = 0;
        }
        if (!state.comicIdMap[comicId]) {
            return;
        }
        state.comicRecordMap[comicId] = ratio;
    },
};
