<!-- 热门列表 -->
<template>
    <div class="hot-list" ref="hotListDom">
        <div class="header-bg">
            <HeaderComp class="activity-header" :title="title" @return="doBack" />
        </div>
        <Card
            v-for="comic in comicsInfo"
            :key="comic.comicTopicId"
            :comicInfo="comic"
            @jumpToComic="jumpToComic"
        />
    </div>
</template>

<script>
import Card from '@/views/HotList/components/Card.vue';
import { get } from '@/modules/request.js';
import { mapState } from 'vuex';
import jump from '@/views/Index/jump.js';
import HeaderComp from '@/components/header/index.vue';

export default {
    name: 'HotList',
    components: {
        HeaderComp,
        Card,
    },
    data() {
        return {
            comicsInfo: [],
            // 是否加载完数据
            isFinish: false,
            title: '热门推荐',
            hasHistory: false,
        };
    },
    created() {
        this.getAlias();
        this.getData();
    },
    computed: {
        ...mapState(['userInfo']),
    },
    methods: {
        doBack() {
            if (this.hasHistory) {
                this.$router.back();
                return;
            }
            this.$router.replace({
                name: 'Index',
            });
        },
        getAlias() {
            get({
                url: `${process.env.VUE_APP_DISTRIBUTION_HOST}/distribution/website/alias`,
            }).then(({ data }) => {
                document.title = data || '热门推荐';
            });
        },
        getData() {
            get({
                url: `${process.env.VUE_APP_DISTRIBUTION_HOST}/distribution/sales/topic/hot_rec`,
            })
                .then(({ data }) => {
                    this.isFinish = true;
                    this.comicsInfo = data;
                    loading.hide();
                })
                .catch((err) => {
                    loading.hide();
                    this.$toast.show(err.message);
                    history.go(-1);
                });
        },
        jumpToComic(comicInfo) {
            if (comicInfo.continueChapter && comicInfo.continueChapter.chapterId) {
                this.$router.push({
                    name: 'ComicRead',
                    query: {
                        comicId: comicInfo.continueChapter.chapterId,
                        topicId: comicInfo.comicTopicId,
                    },
                    params: {
                        SourceModule: '热门推荐',
                        TriggerPage: '热门推荐',
                    },
                });
                return;
            }
            jump({
                type: 3002,
                target_id: comicInfo.comicTopicId,
                params: {
                    SourceModule: '热门推荐',
                    TriggerPage: '热门推荐',
                },
            });
        },
    },
    beforeRouteEnter(to, from, next) {
        next((vm) => {
            vm.hasHistory = from.name;
        });
    },
};
</script>

<style lang="less" scoped>
.header-bg {
    background: #fff;
}
.hot-list {
    overflow: auto;
    height: 100vh;
    box-sizing: border-box;
    background: #f7f8fa;

    .card:not(:first-child) {
        margin-top: 6px;
    }
    .header + .card {
        margin-top: 2px;
    }
}
</style>
