export default {
    /**
     *  设置入口信息
     */
    SET_ENTRY_CONFIG: 'SET_ENTRY_CONFIG',
    /**
     *  设置活动信息
     */
    SET_RECHARGE_CONFIG: 'SET_RECHARGE_CONFIG',
    /**
     *  设置购买次数
     */
    SET_PURCHASE_NUM: 'SET_PURCHASE_NUM',
};
