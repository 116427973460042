export default {
    /**
     *  设置外推链接漫画
     */
    SET_OUTSIDE_LINK_COMICS: 'SET_OUTSIDE_LINK_COMICS',
    /**
     * 设置当前漫画专题详情
     */
    SET_TOPIC_INFO: 'SET_TOPIC_INFO',
    /**
     * 设置用户性别
     */
    SET_GENDER: 'SET_GENDER',
    /**
     *  设置漫画单个章节详情
     */
    SET_COMIC_INFO: 'SET_COMIC_INFO',
    /**
     *  设置正在展示中的章节ID
     */
    SET_SHOWING_COMIC_ID: 'SET_SHOWING_COMIC_ID',
    /**
     *  设置账户余额
     */
    SET_ACCOUNT_BALANCE: 'SET_ACCOUNT_BALANCE',
    /**
     *  设置链接静态属性
     */
    SET_LINK_STATIC_INFO: 'SET_LINK_STATIC_INFO',
    /**
     *  重制当前漫画数据
     */
    RESET_COMIC_READ_INFO: 'RESET_COMIC_READ_INFO',
    /**
     *  设置用户信息
     */
    SET_USER_INFO: 'SET_USER_INFO',
    /**
     *  设置新作推荐
     */
    SET_RECOMMEND_INFO: 'SET_RECOMMEND_INFO',
    /**
     *  设置热门推荐
     */
    SET_HOT_LIST: 'SET_HOT_LIST',
    /**
     *  记录漫画阅读进度
     */
    SET_COMIC_RECORD: 'SET_COMIC_RECORD',
};
