<template>
    <div class="pay-types-wrap">
        <p class="title">支付方式</p>
        <div
            v-for="item in validPayTypes"
            :key="item.payType"
            class="pay-type-item"
            @click="changeActive(item.payType)"
        >
            <img :src="item.icon" alt="" class="type-img" />
            <span class="type-desc">{{ item.desc }}</span>
            <div class="choose-wrap" :class="{ active: activeType === item.payType }"></div>
        </div>
    </div>
</template>

<script>
const TYPE_ALIPAY_H5 = 9;
const TYPE_WX_H5 = 11;
const VALID_PAY_TYPE = [TYPE_WX_H5, TYPE_ALIPAY_H5];
const PAY_TYPE_MAP = {
    [TYPE_ALIPAY_H5]: '支付宝',
    [TYPE_WX_H5]: '微信支付',
};
const ICON_TYPE_MAP = {
    [TYPE_ALIPAY_H5]: require('./img/icon-ali.png'),
    [TYPE_WX_H5]: require('./img/icon-wx.png'),
};
export default {
    data() {
        return {
            activeType: TYPE_WX_H5,
        };
    },
    props: {
        payTypes: {
            type: Array,
            default: () => {
                return [];
            },
        },
    },
    computed: {
        validPayTypes() {
            return this.payTypes
                .filter((item) => VALID_PAY_TYPE.includes(item.pay_type))
                .sort(
                    (a, b) =>
                        VALID_PAY_TYPE.indexOf(a.pay_type) - VALID_PAY_TYPE.indexOf(b.pay_type)
                )
                .map((item) => {
                    const { pay_type: payType } = item;
                    return {
                        payType: payType,
                        desc: PAY_TYPE_MAP[payType],
                        icon: ICON_TYPE_MAP[payType],
                    };
                });
        },
    },
    methods: {
        changeActive(payType) {
            this.activeType = payType;
            this.$emit('change', payType);
        },
    },
};
</script>

<style lang="less" scoped>
@import '~@/assets/vws.less';

.pay-types-wrap {
    .title {
        margin: 12px 0;
        font-size: 14px;
        color: #000;
    }
    .type-img {
        .vws(width, 56.25px);
        .vws(height, 56.25px);
    }
    .pay-type-item {
        display: flex;
        align-items: center;
        margin-bottom: 12px;
        &:last-child {
            margin-bottom: 0;
        }
    }
    .type-desc {
        margin-left: 10px;
        font-size: 14px;
        color: #666;
        flex: 1;
    }
    .choose-wrap {
        .vws(width, 42px);
        .vws(height, 42px);

        background: url('./img/icon-empty.png') no-repeat;
        background-size: 100% 100%;
        &.active {
            background: url('./img/icon-active.png') no-repeat;
            background-size: 100% 100%;
        }
    }
}
</style>
