import { query } from '@kk/utils';

export default {
    userVipInfo(state) {
        return state.userInfo.vipDto || {};
    },
    isVipUser(state) {
        return state.userInfo.vipDto?.vipStatus === 1;
    },
    isVipExpire(state) {
        return state.userInfo.vipDto?.vipStatus === 2;
    },
    canBuyVip(state) {
        const { showVip = false } = state.userInfo.vipDto || {};
        return showVip;
    },
    canFeelVip(state, getters) {
        return getters.isVipUser || getters.canBuyVip || getters.isVipExpire;
    },
    vipPriceDifference(state) {
        let { vipPriceDifference = 0 } = state.userInfo.vipDto || {};
        vipPriceDifference =
            vipPriceDifference % 100
                ? (vipPriceDifference / 100).toFixed(2)
                : vipPriceDifference / 100;
        return vipPriceDifference;
    },
    discountVipType(state) {
        const { discountVipType } = state.userInfo.vipDto || {};
        return Number(discountVipType);
    },
    showingComic(state) {
        const { comicIdMap, showingComicId } = state;
        if (showingComicId && comicIdMap && comicIdMap[showingComicId]) {
            return comicIdMap[showingComicId];
        }
        return {};
    },
    genderTabDefaultIndex(state) {
        // 性别默认取数规则：
        // 1. 取url上，0-男；1-女
        // 2. 取微信性别，服务端下发，0-未知；1-男；2-女
        // 3. 下发的如果未知，默认取女
        const genderFromUrl = query.get('gender');
        const { userInfo } = state;
        let gender;
        if (genderFromUrl) {
            gender = Number(genderFromUrl);
        } else {
            gender = userInfo.gender === 2 ? 1 : 0;
        }
        return gender;
    },
    channelId(state) {
        const { isLogin = false } = state.userInfo || {};
        if (isLogin) {
            return state.userInfo.channelId;
        }
        const { cps = '' } = state.staticInfo || {};
        const cpsArr = cps.split('_');
        if (cpsArr.length > 1 && Number(cpsArr[1]) > 0) {
            return cpsArr[1];
        }
        return process.env.VUE_APP_DEFAULT_CHANNEL_ID;
    },
};
