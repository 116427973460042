<template>
    <div class="rules">
        购买书币说明：
        <p v-for="(item, index) in baseInfo" :key="item">{{ index + 1 }}.{{ item }}</p>
    </div>
</template>
<script>
export default {
    props: {
        topicPrice: {
            type: Number,
            default: 0,
        },
    },
    data: function () {
        return {
            baseInfo: [
                '本平台人民币与书币兑换比率：1元=100书币。',
                '.购买书币时所赠送的书币存在有效期，有效期：自您成功购买书币之日起5个自然日。逾期未消费的，将自动失效；消费时优先扣除所赠送的书币。',
                '若您是未满十八周岁的未成年人的，在本平台进行购买书币前需获得家长同意或其他法定监护人的同意，点击购买书币后即视为您已经得您的家长或其他法定监护人的明确同意。',
                '本平台倡导理性消费。购买成功后,平台即时收取相应费用,书币为虚拟币，非因法定事由，不提供退费服务,敬请谅解。',
                '若您在使用本平台服务过程中存在以营利、经营等非个人使用目的购买/使用书币、利用网络漏洞、作弊工具进行购买消费等违法违规或其他不正当行为的，本平台有权中断或终止向您提供相应的服务，不予返还您购买书币时的现金金额。',
                '本平台可根据运营情况调整相关服务规则，具体请以平台页面显示的为准。',
            ],
        };
    },
    created() {
        if (this.topicPrice > 0) {
            this.baseInfo.splice(0, 0, `当话单价${this.topicPrice}书币`);
        }
    },
};
</script>
<style lang="less" scoped>
@import '~@/assets/vws.less';
.rules {
    padding-top: 28px;
    padding-bottom: 0;
    .vws(padding-left, 30px);
    .vws(padding-right, 30px);

    font-size: 12px;
    line-height: 22px;
    color: #999;
}
</style>
