/** @author xubowei
 * 该文件内定义 通用方法
 */

export default {
    // 获取UA中设置到请求头的参数
    getUA({ userAgent = '' } = {}) {
        userAgent = navigator.userAgent;
        let os = { type: 'unknown', version: 0 };
        if (!userAgent) {
            return {
                browserName: '',
                os,
            };
        }
        const u = userAgent;

        let browserName = u.split(' ')[u.split(' ').length - 1];
        browserName = browserName.replace(/\/?\d+.*/gim, '');
        if (browserName == '') {
            browserName = u.split(' ')[u.split(' ').length - 2];
            browserName = browserName ? browserName.replace(/\/?\d+.*/gim, '') : '';
        }
        if (u.indexOf('Mac OS X') > -1) {
            // ios
            let regStr = /OS [\d._]*/gi;
            let verinfo = u.match(regStr);
            os = {
                type: 'iPhone',
                version: (verinfo + '').replace(/[^0-9|_.]/gi, '').replace(/_/gi, '.'),
            };
            if (u.indexOf('Macintosh;') > -1) {
                regStr = /Intel Mac OS X [\d_]*/gi;
                verinfo = u.match(regStr);
                os = {
                    // 节前上线，避免额外的服务上线，由前端统一处理pc的ua的问题：mac归因至 iphone
                    // type: 'Mac',
                    type: 'iPhone',
                    version: (verinfo + '').replace(/[^0-9|_]/gi, '').replace(/_/gi, '.'),
                };
            }
            return {
                browserName,
                os,
            };
        }
        if (u.indexOf('Android') > -1 || u.indexOf('Linux') > -1) {
            // android
            os = {
                type: 'Android',
                version: u.substr(
                    u.indexOf('Android') + 8,
                    u.indexOf(';', u.indexOf('Android')) - u.indexOf('Android') - 8
                ),
            };
            return {
                browserName,
                os,
            };
        }
        if (u.indexOf('BB10') > -1) {
            // 黑莓bb10系统
            os = {
                type: 'blackberry',
                version: u.substr(
                    u.indexOf('BB10') + 5,
                    u.indexOf(';', u.indexOf('BB10')) - u.indexOf('BB10') - 5
                ),
            };
            return {
                browserName,
                os,
            };
        }
        if (u.indexOf('IEMobile') > -1) {
            // windows phone
            os = {
                type: 'winphone',
                version: u.substr(
                    u.indexOf('IEMobile') + 9,
                    u.indexOf(';', u.indexOf('IEMobile')) - u.indexOf('IEMobile') - 9
                ),
            };
            return {
                browserName,
                os,
            };
        }

        return {
            browserName,
            os,
        };
    },
};
