<template>
    <div class="recommend-wrap" id="recommendWrap">
        <div class="comic-tips">
            <div class="tips-label" :class="recommendType"></div>
            <div class="tips-text" :class="recommendType"></div>
        </div>
        <div class="recommend-title" ref="recommendTitleRef">
            <div class="recommend-title-text">为你推荐</div>
            <div class="to-index-btn" @click="goIndex">去书城首页</div>
        </div>
        <div class="comic-info">
            <div class="comic-img">
                <img :src="recommendTopic.cover_image_url" alt="" />
            </div>
            <div class="comic-detail">
                <div class="comic-title">{{ recommendTopic.title }}</div>
                <div class="comic-desc">{{ recommendTopic.description }}</div>
                <div class="comic-labels">
                    <div v-for="label in recommendTopic.tags" class="label-item" :key="label">
                        {{ label }}
                    </div>
                </div>
            </div>
        </div>
        <div class="comic-content">
            <!-- 计算所有图片高度 -->
            <div
                class="content-img-wrap"
                :style="{ height: pic.placeholderHeight + '%' }"
                v-for="pic in picList"
                :key="pic.id"
            >
                <img class="content-img" :src="pic.url" alt="" />
            </div>
        </div>
        <div @click="toNextFromRecommend()" class="to-next">继续阅读下一话</div>
    </div>
</template>
<script>
import { mapState } from 'vuex';
import { post } from '@/modules/request';

export default {
    data: function () {
        return {
            canTrack: false,
            scrollDom: null,
            isReport: false,
        };
    },
    watch: {
        scrollTop(newVal) {
            this.setRecommendShowStatus(newVal);
            const clientHeight = screen.height;
            const recommendTop = this.scrollDom.offsetTop;
            const showDis = newVal + clientHeight - recommendTop;
            if (showDis <= 0) {
                this.canTrack = false;
                return;
            }
            if (!this.canTrack && showDis > 0) {
                this.canTrack = true;
                this.$emit('beforeShow');
                this.sa('CommonPageOpen', {
                    uid: this.$store.state.userInfo.uid,
                    CurPage: '推荐页',
                    PrePage: this.$parent.triggerPage,
                });
            }
            const recommendHeight = this.scrollDom.offsetHeight;
            const readRatio = showDis / recommendHeight;
            if (readRatio > 0.7) {
                this.doReport(readRatio);
            }
        },
    },
    computed: {
        ...mapState(['recommendInfo']),
        recommendType() {
            return this.recommendInfo.recommendType;
        },
        recommendTopic() {
            return this.recommendInfo.topic_info;
        },
        picList() {
            return this.recommendInfo.comic_info.comic_images.map((item) => {
                return {
                    ...item,
                    placeholderHeight: ((100 * item.height) / item.width).toFixed(2),
                };
            });
        },
        scrollTop() {
            return this.$parent.scrollTop;
        },
    },
    methods: {
        goIndex() {
            this.sa('CommonBtnClk', {
                ButtonName: '去书城首页_漫尾',
                TriggerPage: '漫画详情页',
            });
            this.$router.push({
                name: 'Index',
            });
        },
        setRecommendShowStatus(scrollTop) {
            const recommendContentFirstEl = this.$refs.recommendTitleRef;
            if (this.recommendInfo && recommendContentFirstEl) {
                this.$emit('setRecommendShowStatus', scrollTop > recommendContentFirstEl.offsetTop);
            }
        },
        doReport(readImgPos) {
            if (this.isReport) {
                return;
            }
            const readRatio = readImgPos > 1 ? 1 : readImgPos;
            this.isReport = true;
            return post({
                url: `${process.env.VUE_APP_DISTRIBUTION_HOST}/distribution/comic/history/report`,
                data: {
                    topicId: this.recommendTopic.id,
                    comicId: this.recommendInfo.comic_info.id,
                    totalCount: this.picList.length,
                    readPos: Number.parseInt(this.picList.length * readRatio),
                },
            });
        },
        toNextFromRecommend() {
            this.sa('CommonBtnClk', {
                uid: this.$store.state.userInfo.uid,
                CurPage: '推荐页',
                ButtonName: '继续阅读下一话',
            });
            loading.show();
            this.$emit('setRecommendShowStatus', false);
            this.$emit('update', {
                topicId: this.recommendTopic.id,
                comicId: this.recommendInfo.next_comic_info.next_comic_id,
            });
        },
    },
    mounted() {
        this.scrollDom = document.getElementById('recommendWrap');
    },
};
</script>
<style lang="less" scoped>
@import '~@/assets/vws.less';

.recommend-wrap {
    background: #fff;
}
.comic-tips {
    padding: 58px 0;
    font-size: 16px;
}
.tips-label {
    height: 2.3em;
}
.tips-label.continue {
    background: url('./label_continue.png') no-repeat center / auto 100%;
}
.tips-label.end {
    background: url('./label_end.png') no-repeat center / auto 100%;
}
.tips-text {
    margin-top: 14px;
    height: 2.6em;
}
.tips-text.continue {
    background: url('./text_continue.png') no-repeat center / auto 100%;
}
.tips-text.end {
    background: url('./text_end.png') no-repeat center / auto 100%;
}
.recommend-title {
    display: flex;
    align-items: flex-start;
    padding: 2px 12px;
}
.recommend-title-text {
    width: 100%;
    font-size: 17px;
    font-weight: 700;
    color: #333;
    line-height: 22px;
}
.to-index-btn {
    flex: none;
    display: flex;
    align-items: flex-start;
    font-size: 12px;
    font-family: PingFangSC-Medium, 'PingFang SC';
    font-weight: bold;
    color: #f44;
    line-height: 16px;
}
.to-index-btn::after {
    content: '';
    width: 16px;
    height: 16px;
    background: url('./arrow-right.png') center / 100%;
}
.comic-info {
    display: flex;
    overflow: hidden;
    margin-top: 10px;
    padding: 0 12px;
}
.comic-img {
    .vws(width, 225px);
    .vws(height, 288px);

    flex-shrink: 0;
}
.comic-img img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    border-radius: 3px;
}
.comic-detail {
    margin-left: 12px;
}
.comic-title {
    font-size: 15px;
    font-weight: 700;
    color: #333;
}
.comic-desc {
    display: -webkit-box;
    overflow: hidden;
    margin-top: 4px;
    font-size: 13px;
    text-overflow: -o-ellipsis-lastline;
    text-overflow: ellipsis;
    color: #999;
    -webkit-line-clamp: 2;
    line-clamp: 2;
    -webkit-box-orient: vertical;
    line-height: 21px;
}
.comic-labels {
    display: flex;
    margin-top: 9px;
}
.label-item {
    margin-right: 6px;
    padding: 1px 4px;
    font-size: 12px;
    border-radius: 2px;
    color: #f44;
    background: #fef1f1;
}
.comic-content {
    margin-top: 20px;
}
.to-next {
    padding: 26px 0;
    font-size: 12px;
    font-weight: 700;
    text-align: center;
    color: #333;
}
.content-img-wrap {
    width: 100%;
    font-size: 0;
}
.content-img {
    width: 100%;
    height: 100%;
}
</style>
