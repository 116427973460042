// import store from '@/store/index';
// import utils from '@/modules/utils.js';
// import base64 from '@/modules/base64.js';
// import { post } from '@/modules/request';
// const uaUtil = utils.getUA({userAgent: navigator.userAgent});
// const devicePixelRatio = window.devicePixelRatio || 1;
// const resolution = screen.width * devicePixelRatio + '*' + screen.height * devicePixelRatio;
// const type = uaUtil.os.type || '';
// const version = uaUtil.os.version || '';

const reportToData = function (eventName, param) {
    console.info(eventName, param);
    // 暂时不上报，先注释
    // const {userInfo} = store.state;
    // let postData = {
    //   distinct_id: String(userInfo.uid),
    //   time: Date.now(),
    //   event: eventName,
    //   properties: {
    //     SubscriptionName: '', // 公众号名称
    //     SourcePlatform: '分销M站',
    //     IsNew: userInfo.newUser,
    //     ...param,
    //   }
    // };
    // return post({
    //   url: process.env.VUE_APP_API + '/v1/app_data',
    //   headers: {
    //     // 上报到数据组-M站项目 需要带有`User-Agent-M`header
    //     'User-Agent-M': `MKuaikan/1.1.0/11000(${type};${version};mkuaikan;${resolution})`,
    //     'Muid': userInfo.uid
    //   },
    //   data: {
    //     data: base64.encode(JSON.stringify(postData))
    //   }
    // });
};

export { reportToData };
