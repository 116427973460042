<!-- 漫画阅读 -->
<template>
    <div :style="{ height: placeholderHeight }">
        <template v-if="showImg">
            <div
                class="ComicPics"
                v-for="(item, index) in imgListWithHeight"
                :style="{ height: item.placeholderHeight + '%' }"
                :key="index"
            >
                <img v-lazyLoad :data-src="item.url" class="image" />
            </div>
        </template>
    </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
    name: 'ComicPics',
    // eslint-disable-next-line vue/require-prop-types
    props: ['comicId'],
    computed: {
        ...mapState(['comicIdMap', 'showingComicId']),
        imgList() {
            return this.comicIdMap[this.comicId].comicImages;
        },
        placeholderHeight() {
            const height = this.imgListWithHeight.reduce((a, b) => {
                return a + Number(b.placeholderHeight);
            }, 0);
            return height.toFixed(2) + '%';
        },
        imgListWithHeight() {
            return this.imgList.map((item) => {
                return {
                    ...item,
                    // 单位为 %
                    placeholderHeight: ((100 * item.height) / item.width).toFixed(2),
                };
            });
        },
        showImg() {
            const curComicId = this.$parent.comicId;
            const showComicTarget = this.comicIdMap[this.showingComicId];
            if (!showComicTarget) {
                return;
            }
            const showList = [
                showComicTarget.previousComicId,
                this.showingComicId,
                showComicTarget.nextComicId,
            ];
            return showList.find((item) => item == curComicId);
        },
    },
};
</script>

<style lang="less">
.ComicPics {
    position: relative;
    width: 100%;
    .image {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center top;
    }
}
</style>
