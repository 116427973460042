/* eslint-disable camelcase */
import { get } from '@/modules/request.js';
import store from '@/store';
import { CHARGE_GOOD_TYPE_VIP } from '@/modules/charge.js';

const TYPE_WX_H5 = 11;

// 充值优惠和普通充值选项
const rechargeSelectMixin = {
    data() {
        return {
            chargeList: [],
            discountRechargeGoods: [],
            vipRechargeList: [],
            payTypes: [],
            activePayType: TYPE_WX_H5,
            curChargeTarget: undefined,
        };
    },
    methods: {
        onChoose(item) {
            this.curChargeTarget = item;
        },
        getChargeInfo() {
            let data = {};
            if (store.state.userInfo.isLogin) {
                data.channelId = store.state.userInfo.channelId;
            }
            return get({
                url: process.env.VUE_APP_API + '/distribution/sales/recharge/page',
                data,
            }).then((res) => {
                loading.hide();
                const { recharges = [], user_account } = res.data;
                const balance = this.isApple
                    ? user_account.ios_balance
                    : user_account.non_ios_balance;
                this.$store.commit('SET_ACCOUNT_BALANCE', balance);
                if (recharges.length) {
                    const {
                        recharge_goods: chargeList,
                        discount_recharge_goods: discountRechargeGoods,
                        pay_types: payTypes,
                        vip_recharge_goods: vipRechargeList,
                        first_match_recharge_category: firstMatchRechargeCategory,
                        match_recharge_goods_index: matchRechargeGoodsIndex,
                    } = recharges[0];
                    this.chargeList = chargeList || [];
                    this.discountRechargeGoods = discountRechargeGoods || [];
                    this.vipRechargeList = vipRechargeList.map((item) =>
                        this.getVipRechargeInfo(item)
                    );
                    const matchMap = {
                        1: this.chargeList,
                        3: this.vipRechargeList,
                    };
                    if (
                        matchMap[firstMatchRechargeCategory] &&
                        matchMap[firstMatchRechargeCategory][matchRechargeGoodsIndex]
                    ) {
                        this.onChoose(
                            matchMap[firstMatchRechargeCategory][matchRechargeGoodsIndex]
                        );
                    } else {
                        this.onChoose(this.chargeList[0] || this.vipRechargeList[0]);
                    }
                    this.payTypes = payTypes || [];
                }
                return res;
            });
        },
        changePayType(payType) {
            this.activePayType = payType;
        },
        getVipRechargeInfo(vipRechargeInfo) {
            if (vipRechargeInfo) {
                const {
                    name,
                    price: real_price,
                    description,
                    goodsId: id,
                    timeLimitedDiscountPrice,
                    originPrice,
                    vipPriceDifference,
                } = vipRechargeInfo;
                return {
                    name,
                    real_price,
                    realPriceStr: this.formatPrice(real_price),
                    description,
                    id,
                    good_type: CHARGE_GOOD_TYPE_VIP,
                    timeLimitedDiscountPrice,
                    timeLimitedDiscountPriceStr: this.formatPrice(timeLimitedDiscountPrice),
                    originPrice,
                    originPriceStr: this.formatPrice(originPrice),
                    vipPriceDifference,
                    vipPriceDifferenceStr: this.formatPrice(vipPriceDifference),
                };
            }
        },
        formatPrice(price) {
            return price % 100 ? (price / 100).toFixed(2) : price / 100;
        },
        confirmCharge() {
            if (this.curChargeTarget.good_type !== CHARGE_GOOD_TYPE_VIP) {
                return Promise.resolve();
            }
            return new Promise((resolve, reject) => {
                const dialog = this.DialogController.asyncShow({
                    component: () =>
                        import(
                            /* webpackChunkName: "dialog" */ '@/components/popup/confirm/index.vue'
                        ),
                    contentProps: {
                        title: '温馨提示',
                        message:
                            '标记“VIP”的漫画才可享受VIP免费看权益，未标记“VIP”的漫画不享受VIP免费看权益，确定购买VIP？',
                        actionFn: (action) => {
                            if (action === 'confirm') {
                                resolve();
                            } else {
                                reject('取消购买VIP');
                            }
                            dialog.close();
                        },
                    },
                });
                this.$on('hook:beforeDestroy', () => {
                    dialog.close();
                });
            });
        },
    },
};

export default rechargeSelectMixin;
