<!-- 作品卡片 -->
<template>
    <section class="card" @click="$emit('jumpToComic', comicInfo)">
        <header>
            <p class="comic-title ell">{{ topicAliasOrName }}</p>
            <button class="btn follow">
                全集
                <span class="arrow"></span>
            </button>
        </header>
        <div class="labels">
            <div v-for="(label, index) in comicInfo.labels" :key="index" class="label">
                {{ label }}
            </div>
        </div>
        <div class="cover">
            <img
                v-if="comicInfo.topicImgUrl"
                v-lazyLoad
                :data-src="comicInfo.topicImgUrl | compress(500)"
                alt="cover"
            />
        </div>
        <div class="title ell" v-if="comicInfo.continueChapter">
            {{ comicInfo.continueChapter.chapterName }}
        </div>
    </section>
</template>

<script>
export default {
    name: 'Card',
    // eslint-disable-next-line vue/require-prop-types
    props: ['comicInfo'],
    computed: {
        topicAliasOrName() {
            return this.comicInfo.topicAlias || this.comicInfo.topicName;
        },
    },
};
</script>

<style lang="less" scoped>
.card {
    padding: 8px;
    background: #fff;

    .ell {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    header {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .comic-title {
            max-width: 75%;
            font-size: 16px;
            color: #333;
        }
        .btn {
            font-size: 12px;
            border-radius: 20px;
            color: #333;
            line-height: 1;
        }
        .follow {
            display: inline-flex;
            align-items: center;
            font-size: 13px;
            color: #999;

            .arrow {
                width: 16px;
                height: 16px;
                background: url('./arrow.png') no-repeat center/contain;
            }
        }
    }
    .labels {
        display: flex;
        overflow-x: auto;
        margin: 5px 0 10px;

        &::-webkit-scrollbar {
            display: none;
            width: 0 !important;
        }

        .label {
            margin-right: 4px;
            padding: 3px 8px;
            font-size: 11px;
            border-radius: 20px;
            color: #b89668;
            background: #f9f3e8;
        }
    }
    .cover {
        overflow: hidden;
        height: 54vw;
        border-radius: 4px;
        background: #fff;

        img {
            width: 100%;
            height: 100%;
        }
    }
    .title {
        margin: 15px 0 6px;
        max-width: 80%;
        font-size: 15px;
        text-align: left;
        color: #999;
        line-height: 1.2;
    }
}
</style>
