import utils from './utils';
import config from './config';

const uaUtil = utils.getUA({ userAgent: navigator.userAgent });
const devicePixelRatio = window.devicePixelRatio || 1;
const resolution = screen.width * devicePixelRatio + '*' + screen.height * devicePixelRatio;
const type = uaUtil.os.type || '';
const version = uaUtil.os.version || '';
import store from '@/store/index';
import types from '@/store/types';
import toast from '@kk/toast';

request.after = function (res) {
    var code = res.code;
    var message = res.message;
    if (code !== 200 && code !== 20407) {
        if (code === 401 && store.state.userInfo.isLogin) {
            store.commit(types.SET_USER_INFO, {
                isLogin: false,
                uid: -1,
            });
        }
        toast.show(message);
        // 20407: 充值活动 - 用户已经用尽活动参与机会
        var err = new Error(message || '服务器数据异常');
        err.code = code;
        throw err;
    }
};

function getClientInfo() {
    let channelId = Number(store.state.userInfo.channelId);
    channelId = isNaN(channelId) ? 0 : channelId;

    return JSON.stringify({
        clientType: 'M_WEB',
        channelId,
    });
}

function requestOptionHandler(options) {
    const headers = options.headers || {};
    options.headers = {
        ...headers,
        clientInfo: getClientInfo(),
        'User-Agent-B': `BKuaikan/1.1.0/11000(${type};${version};mkuaikan;${resolution})`,
    };
    options.data = Object.assign({ channelType: config.CHANNEL_TYPE }, options.data);
}

const get = (opt) => {
    requestOptionHandler(opt);
    return request(opt);
};
const post = (opt) => {
    opt.type = 'POST';
    requestOptionHandler(opt);
    return request(opt);
};

export { get, post };
