export default {
    canJoinAct(state) {
        // 可否参加活动
        const { serviceTime, endTime, purchaseLimit } = state.rechargeConfig;
        // 可参加活动条件：未到截止时间 && 未达到参与活动上限（purchaseLimit = 0 表示不限次数）
        return (
            endTime > serviceTime && (purchaseLimit === 0 || state.purchaseCount < purchaseLimit)
        );
    },
};
