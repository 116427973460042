<template>
    <div class="tool-item">
        <div class="left">
            <div class="icon-teenager"></div>
            <span>青少年模式</span>
        </div>
        <div class="select-wrap" :class="{ active: active }" @click="changeActive">
            <div class="slider"></div>
        </div>
    </div>
</template>

<script>
import { storageKey } from './Controller.js';

export default {
    data: function () {
        return {
            active: false,
        };
    },
    methods: {
        changeActive() {
            this.active = !this.active;
            const message = this.active ? '已开启青少年模式' : '已关闭青少年模式';
            this.$toast.show(message);
            if (this.active) {
                localStorage.setItem(storageKey, 'true');
            } else {
                localStorage.setItem(storageKey, 'false');
            }
        },
    },
    created() {
        this.active = localStorage.getItem(storageKey) === 'true';
    },
};
</script>

<style lang="less" scoped>
@import '~@/assets/vws.less';

.tool-item {
    font-size: 16px;
    font-weight: normal;
    color: #333;
}
.left {
    display: flex;
    align-items: center;
}
.icon-teenager {
    margin-right: 10px;
    .vws(width, 44px);
    .vws(height, 44px);

    background: url('./img/icon_teenager.png') no-repeat center / contain;
}
.select-wrap {
    position: relative;
    .vws(width, 88px);
    .vws(height, 48px);
    .vws(border-radius, 24px);

    background: #999;
    .slider {
        position: absolute;
        .vws(top, 4.5px);
        .vws(left, 4.5px);
        .vws(width, 39px);
        .vws(height, 39px);

        border-radius: 50%;
        background: #fff;
        transition: left 0.3s ease;
    }
    &.active {
        background: #ff4443;
        .slider {
            .vws(left, 44.3px);
            // left: 5.9vw;
        }
    }
}
</style>
