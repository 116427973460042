import utils from '@/modules/utils.js';
import { cookie, webview } from '@kk/utils';
import store from '@/store';

const CHARGE_GOOD_TYPE_KKB = 1;
const CHARGE_GOOD_TYPE_VIP = 2;
const CHARGE_GOOD_TYPE_WHOLE_BOOK = 3;

const CUSTOM_CHARGE_GOOD_TYPES = [CHARGE_GOOD_TYPE_VIP, CHARGE_GOOD_TYPE_WHOLE_BOOK];

const KKB_PACK_DEFAULT = 1; // 默认kkb包装类型
const KKB_PACK_DISCOUNT = 2; // kkb的充值优惠包装类型

const CHARGE_PAGE_CHARGE_CENTER = 1; // 充值中心
const CHARGE_PAGE_CHARGE_POPUP = 2; // 充值半屏
const CHARGE_PAGE_CHARGE_ACTIVITY = 3; // 充值活动

// CHARGE_PACK_TYPE
const CHARGE_PACK_TYPE_KKB = 1; // 书币充值

/**
 * 充值工具
 * 使用方法：
  const utils = new ChargeUtils({
    goodId: 123,
    cps: '100004_127_123',
    ... 除这两个参数外其他参数有默认值，传入可覆盖
  });
  utils.charge();

  wiki地址: https://wiki.quickcan.com/pages/viewpage.action?pageId=506954216
 */

const getPayUserAgent = function () {
    const devicePixelRatio = window.devicePixelRatio || 1;
    const resolution = screen.width * devicePixelRatio + '*' + screen.height * devicePixelRatio;
    const uaUtil = utils.getUA({ userAgent: navigator.userAgent });
    const type = uaUtil.os.type || '';
    const version = uaUtil.os.version || '';
    return `BKuaikan/1.1.0/11000(${type};${version};bkuaikan;${resolution})`;
};

class ChargeUtils {
    constructor(options) {
        const { channelId, marketerId } = store.state.userInfo;
        const { cps = '' } = store.state.staticInfo;
        const defaultParams = {
            // 充值接口所需字段
            thirdActivity: '', // string 活动ID
            goodType: CHARGE_GOOD_TYPE_KKB, // int 1.kkb 2.vip 3.整本购 => wx-pay 1.kkb 2.自定义商品
            goodId: 0, // int 充值购买内容的id
            goodPrice: 0,
            comicId: 0, // int 漫画id
            topicId: 0, // int 专题id
            sourceType: 3, // int 1：签到，2：皇冠，3：作品，4：礼包
            inviteCode: '', // string 拉新活动时候会有邀请码
            payType: 11, // int 支付平台 9支付宝h5, 10微信native, 11微信browser, 12QQnative,14 QQbrowser

            // 埋点所需字段
            origin: '', // string  1. 下单接口参数from（origin='':0,微信环境:2,origin=mobile则5，微信小程序:3）; 2.vip埋点BeMembershipResult属性EntranceName的值
            triggerPage: '', // string 埋点属性TriggerPage  新充值中间页已经删除这个参数 后续将小程序充值也替换流程后可删除
            noticeType: 'czlb', // string dxxm:定向限免, czlb:充值礼包, ... 新充值中间页已经删除这个参数 后续将小程序充值也替换流程后可删除
            passToken: cookie.get('passToken'), //string B端分销页面支付时传递
            from: 29,
            paySource: 2,
            cps: cps || `${marketerId}_${channelId}_0`,

            // 其他
            useragent: getPayUserAgent(),
            redirectUrl: location.href, // string 支付成功之后想跳回的h5地址

            //服务端用于表示用户在vip充值活动页面进行充值的VIP
            source: '',
            currentChargePage: '',
            chargeType: '', // 充值场景，值对应：CHARGE_PACK_TYPE
        };
        this.optionsValidate(options);
        this.params = Object.assign(defaultParams, options);

        if (!this.params.passToken) {
            console.error({ msg: 'cookie缺失passToken', cookie });
        }
    }

    /**
     * 充值
     * @returns
     */
    async charge() {
        const isCustomCharge = CUSTOM_CHARGE_GOOD_TYPES.indexOf(this.params.goodType) !== -1;
        const chargeParams = isCustomCharge
            ? this.getCustomChargeParams()
            : this.getKkbChargeParams();
        await this.normalCharge(chargeParams);
    }

    optionsValidate(options) {
        const requiredList = ['goodId'];

        for (let key of requiredList) {
            if (options[key] == null) {
                throw new Error(`${key} 为必要参数`);
            }
        }

        return true;
    }

    /**
     * 非小程序充值
     * @param {object} chargeParams 充值信息
     * @returns
     */
    normalCharge(chargeParams) {
        return new Promise((resolve, reject) => {
            try {
                const payHost = process.env.VUE_APP_DISTRIBUTION_PAY_MIDDLE_HOST;
                location.href = `${payHost}${
                    process.env.VUE_APP_HOST_ENV === 'preview' ? '/prev' : ''
                }/distribution-h5/wx-pay/index.html?v_param=${encodeURIComponent(
                    JSON.stringify(chargeParams)
                )}`;
                resolve();
            } catch (error) {
                reject(error);
            }
        });
    }

    getCustomChargeParams() {
        const params = this.params;
        const { uid: userId, marketerId, channelId } = store.state.userInfo || {};
        let payInfo = {
            ...(this.params.goodType === CHARGE_GOOD_TYPE_VIP
                ? { source: this.params.source }
                : {}),
            // messageId、messageType、messageTaskId,  messageSeq, messageVersion
        };
        const data = {
            //接口所需字段
            payType: params.payType,
            marketerId,
            channelId,
            userId,
            cps: params.cps,
            goodType: 2, //自定义商品
            payInfo: JSON.stringify(payInfo),
            activityId: '',
            total_price: params.goodPrice,
            good_id: params.goodId,
            useragent: params.useragent,
            redirect_url: encodeURIComponent(params.redirectUrl),
            pass_token: params.passToken || cookie.get('passToken'),
            trigger_page: params.triggerPage,
        };
        if (!data.pass_token) {
            console.info({ msg: `cookie为：${document.cookie}` });
            throw new Error('充值参数缺失pass_token');
        }
        return data;
    }
    /* eslint-disable-next-line */
    getKkbChargeParams() {
        const params = this.params;
        const { userInfo } = store.state;
        const { uid: userId, marketerId, channelId } = userInfo || {};
        const data = {
            payType: params.payType,
            goodType: params.goodType,
            goodId: params.goodId,
            origin: params.origin,
            cps: params.cps,
            platform: webview.isAndroid ? 1 : 2, // 1：安卓，2：IOS
            redirectUrl: params.redirectUrl,
            passToken: params.passToken || cookie.get('passToken'),
            useragent: params.useragent,
            from: params.from,
            paySource: params.paySource,
            payInfo: JSON.stringify({
                ua: params.useragent,
                source_type: params.sourceType,
                invite_code: params.inviteCode,
                third_activity: params.thirdActivity,
            }),
            userId,
            marketerId,
            channelId,
            total_price: params.goodPrice,
            trigger_page: params.triggerPage,
        };
        console.info({ msg: '充值参数', data });
        if (!data.passToken) {
            throw new Error('充值参数缺失passToken');
        }
        return {
            ...data,
            pointInfo: JSON.stringify(data), // 给后端用于埋点预留
        };
    }
}

export {
    ChargeUtils,
    CHARGE_GOOD_TYPE_KKB,
    CHARGE_GOOD_TYPE_VIP,
    CHARGE_GOOD_TYPE_WHOLE_BOOK,
    CHARGE_PAGE_CHARGE_CENTER,
    CHARGE_PAGE_CHARGE_POPUP,
    CHARGE_PAGE_CHARGE_ACTIVITY,
    CHARGE_PACK_TYPE_KKB,
    KKB_PACK_DEFAULT,
    KKB_PACK_DISCOUNT,
};
