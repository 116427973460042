import types from './types';
import { query } from '@kk/utils';
import { get, post } from '@/modules/request';

export default {
    getRechargeActivityInfo({ commit }) {
        // 获取充值活动信息
        return get({
            url: process.env.VUE_APP_DISTRIBUTION_HOST + '/distribution/recharge/activity/detail',
            data: {
                // 对于充值活动，这里暂时写死
                type: 1,
                activityId: query.get('activityId') || 0,
            },
        })
            .then((res) => {
                const { data } = res;
                const { entryConfig = {}, purchaseCount } = data;
                commit(types.SET_PURCHASE_NUM, purchaseCount);
                // 记录入口配置
                commit(types.SET_ENTRY_CONFIG, entryConfig);
                // 记录活动信息
                commit(types.SET_RECHARGE_CONFIG, data);
                return res;
            })
            .catch((err) => {
                return err;
            });
    },
    updatePurchaseLimitNum({ state }) {
        // 更新用户参与充值活动次数
        return post({
            url:
                process.env.VUE_APP_DISTRIBUTION_HOST +
                '/distribution/recharge/activity/purchase_count',
            data: {
                activityId: state.rechargeConfig.activityId || query.get('activityId'),
            },
        });
    },
};
