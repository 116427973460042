<!-- 个人中心页面 -->
<template>
    <div class="booklist">
        <PageHeader title="个人中心" @return="toPage('Index')">
            <!-- <span class="icon-sign-in" slot="right" @click="toPage('Checkin')">
                <span class="sub-title">签到</span>
            </span> -->
        </PageHeader>

        <div class="mod-user">
            <!-- <div class="user-avatar">
        <img class="photo" :src="userInfo.avatarUrl ? userInfo.avatarUrl : user_default">
      </div> -->
            <div class="user-name line-limit" v-if="userInfo.nickname">{{ userInfo.nickname }}</div>
            <div class="user-uid line-limit" v-if="userInfo.uid">ID: {{ userInfo.uid }}</div>
        </div>

        <div class="mod-charge">
            <div class="charge-item mod-vip" v-if="userVipInfo.vipStatus > 0">
                <!-- VIP生效 -->
                <template v-if="userVipInfo.vipStatus === 1">
                    <i class="icon icon-vip"></i>
                    <span class="vip-status vip">
                        <span class="time">
                            {{ userVipInfo.expireTime | formatDate('yyyy.MM.dd hh:mm') }} 到期
                        </span>
                        <span class="vip-status__text">VIP生效中</span>
                    </span>
                </template>
                <!-- VIP过期 -->
                <template v-else>
                    <i class="icon icon-vip-expire"></i>
                    <div class="vip vip-status vip-expire">
                        <span class="time" v-if="userVipInfo.expireTime">
                            {{ userVipInfo.expireTime | formatDate('yyyy.MM.dd hh:mm') }}
                        </span>
                        <span>VIP已到期</span>
                        <div class="btn vip-btn" @click="toPage('Charge')" v-if="canBuyVip">
                            续费
                        </div>
                    </div>
                </template>
            </div>
            <div class="charge-item">
                <div class="icon-wallet"></div>
                <span class="kkb">{{ displayAccountBalance }}书币</span>
                <div class="btn" @click="toPage('Charge')">充值</div>
            </div>
        </div>

        <div class="mod-tool">
            <div
                class="tool-item"
                v-for="(item, index) in toolList"
                :key="index"
                @click="jumpTo(item)"
            >
                <div class="left">
                    <div class="icon" :class="item.iconName"></div>
                    <span class="label">{{ item.label }}</span>
                </div>
                <div class="arrow"></div>
            </div>
            <TeenagerSelectItem />
        </div>
    </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
// eslint-disable-next-line camelcase
import user_default from './img/user_default.png';
import PageHeader from '@/components/header';
import TeenagerSelectItem from '@/components/Teenager/SelectItem.vue';

export default {
    name: 'UserInfo',
    components: {
        PageHeader,
        TeenagerSelectItem,
    },
    data() {
        return {
            requesting: false,
            user_default,
            PrePage: '',
            toolList: [
                {
                    iconName: 'icon-trade-record',
                    label: '充值记录',
                    actionType: null,
                    router: {
                        name: 'TradeRecord',
                    },
                },
                {
                    iconName: 'icon-read-record',
                    label: '阅读记录',
                    router: {
                        name: 'RecentRead',
                    },
                },
                {
                    iconName: 'icon-customer-serive',
                    label: '联系客服',
                    router: {
                        name: 'CustomerService',
                    },
                },
            ],
        };
    },
    watch: {
        requesting(val) {
            val ? loading.show() : loading.hide();
        },
    },
    computed: {
        ...mapState(['accountBalance', 'userInfo']),
        ...mapGetters(['userVipInfo', 'canBuyVip']),
        isLogin() {
            return this.userInfo.isLogin;
        },
        displayAccountBalance() {
            if (Number(this.accountBalance) > 0) {
                return this.accountBalance;
            }
            return 0;
        },
    },
    async created() {
        document.title = '个人中心';
    },
    beforeRouteEnter(to, from, next) {
        next((vm) => {
            const routeArr = [
                { name: 'Index', label: '首页' },
                { name: 'Checkin', label: '签到页' },
                { name: 'Charge', label: '充值页' },
            ];
            const lastRoute = routeArr.find((item) => item.name === from.name) || {};
            if (lastRoute.name) vm.PrePage = lastRoute.label;
        });
    },
    mounted() {
        if (this.userInfo.uid) {
            this.$store.dispatch('getCurBalance');
        }
        loading.hide();
        this.sa('CommonPageOpen', {
            uid: this.$store.state.userInfo.uid,
            CurPage: '我的',
            PrePage: this.PrePage,
        });
        this.sa('VisitBookshelfPage', {
            SourcePlatform: '分销M站',
        });
    },
    methods: {
        jumpTo(item) {
            this.sa('CommonBtnClk', {
                uid: this.$store.state.userInfo.uid,
                CurPage: '我的',
                ButtonName: item.label,
            });
            this.$router.push(item.router);
        },
        toPage(name) {
            if (name === 'Charge' || name === 'Checkin') {
                // 返回按钮不需要埋点了
                this.sa('CommonBtnClk', {
                    uid: this.$store.state.userInfo.uid,
                    CurPage: '我的',
                    ButtonName: name === 'Charge' ? '充值' : '签到',
                });
            }
            this.$router.push({ name });
        },
    },
};
</script>

<style lang="less" scoped>
@import '~@/assets/vws.less';

.booklist {
    position: relative;
    overflow: auto;
    overflow: hidden;
    height: 100vh;
    background: #f5f5f5;
    -webkit-overflow-scrolling: touch;

    /deep/ .page-header {
        .icon-sign-in {
            position: absolute;
            top: 9px;
            right: 18px;
            .vws(width, 64px);
            .vws(height, 70px);

            background: url('./img/icon-sign-in.png') no-repeat left top / 100% 100%;
            .sub-title {
                position: absolute;
                top: 34px;
                left: 0;
                .vws(width, 64px);

                margin-top: 2px;
                line-height: 1;
                font-size: 12px;
                font-family: PingFangSC-Regular, 'PingFang SC';
                font-weight: 400;
                color: #333;
            }
        }
    }

    .mod-user {
        position: relative;
        padding: 10px 0 17px;
        width: 100%;

        .user-avatar {
            .vws(width, 140px);
            .vws(height, 140px);

            margin: 0 auto;
            border: 2px solid #eee;
            border-radius: 50%;
            box-shadow: 0 4px 8px 0 rgba(40, 32, 40, 0.06);
            box-sizing: border-box;
            .photo {
                display: block;
                width: 100%;
                height: 100%;
                border-radius: 50%;
            }
        }

        .user-name {
            margin: 16px auto 0;
            max-width: 200px;
            font-size: 20px;
            font-family: PingFangSC-Semibold, 'PingFang SC';
            font-weight: 600;
            text-align: center;
            color: #282028;
        }
        .user-uid {
            margin-top: 2px;
            font-size: 14px;
            font-family: PingFangSC-Regular, 'PingFang SC';
            font-weight: 400;
            text-align: center;
            color: #666;
        }
    }

    .mod-charge {
        margin: 0 auto;
        .vws(margin-left, 24px);
        .vws(margin-right, 24px);

        border-radius: 6px;
        background: #fff;
        box-sizing: border-box;
        .charge-item {
            display: flex;
            align-items: center;
            border-radius: 6px;
            background: #fff;
            box-sizing: border-box;
            .vws(height, 120px);
            .vws(line-height, 120px);
            .vws(padding-left, 20px);
            .vws(padding-right, 20px);
            &.mod-vip {
                font-size: 16px;
                color: #333;
                .icon-vip {
                    background: url('./img/icon-vip.png') no-repeat center / contain;
                }
                .icon-vip-expire {
                    background: url('./img/icon-vip-expire.png') no-repeat center / contain;
                }
                .vip-status {
                    display: flex;
                    align-items: center;
                    width: 100%;
                    .time {
                        margin-right: 10px;
                        font-size: 16px;
                    }
                    &__text {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        margin-left: auto;
                        font-size: 12px;
                        color: rgba(82, 3, 3, 1);
                        background: rgba(255, 246, 195, 1);
                        .vws(height, 46px);
                        .vws(border-radius, 23px);
                        .vws(padding-left, 16px);
                        .vws(padding-right, 16px);
                    }
                    &.vip-expire {
                        color: rgba(153, 153, 153, 1);
                        span {
                            font-weight: initial;
                        }
                    }
                }
                .icon {
                    display: block;
                    .vws(width, 44px);
                    .vws(height, 44px);
                }
            }
            .icon-wallet {
                display: block;
                .vws(width, 44px);
                .vws(height, 44px);

                background: url('./img/icon-wallet.png') no-repeat center / contain;
            }

            .vip,
            .kkb,
            .label {
                margin-left: 10px;
                font-size: 16px;
                font-family: PingFangSC-Medium, 'PingFang SC';
                font-weight: 500;
                color: #333;
            }

            .btn {
                display: inline-block;
                .vws(height, 56px);
                .vws(line-height, 56px);
                .vws(border-radius, 28px);
                .vws(padding-left, 36px);
                .vws(padding-right, 36px);

                margin-left: auto;
                font-size: 12px;
                font-family: PingFangSC-Semibold, 'PingFang SC';
                font-weight: 600;
                text-align: center;
                color: #fff;
                background: #f44;
                box-shadow: 0 3px 6px 0 rgba(255, 20, 61, 0.23);
                box-sizing: border-box;
                &.vip-btn {
                    color: rgba(82, 3, 3, 1);
                    background: #ffe970;
                    box-shadow: none;
                }
            }
        }
    }

    .mod-tool {
        display: flex;
        align-items: center;
        height: 100%;
        border-radius: 6px;
        background: #fff;
        flex-direction: column;
        box-sizing: border-box;
        .vws(line-height, 120px);
        .vws(margin-top, 24px);
        .vws(margin-left, 24px);
        .vws(margin-right, 24px);
        .vws(padding-left, 20px);
        .vws(padding-right, 20px);

        .kkb,
        .label {
            margin-left: 10px;
            font-size: 16px;
            font-family: PingFangSC-Medium, 'PingFang SC';
            font-weight: 500;
            color: #333;
        }
        .tool-item {
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 100%;
            flex-direction: row;
            .vws(height, 112px);
            .left {
                display: flex;
                flex-direction: row;
                align-items: center;
                .icon {
                    display: block;
                    .vws(width, 44px);
                    .vws(height, 44px);
                }
                .icon-trade-record {
                    background: url('./img/icon-trade-record.png') no-repeat center / contain;
                }
                .icon-read-record {
                    background: url('./img/icon-read-record.png') no-repeat center / contain;
                }
                .icon-customer-serive {
                    background: url('./img/icon-phone.png') no-repeat center / contain;
                }
            }
            .arrow {
                .vws(width, 36px);
                .vws(height, 36px);

                transform: rotateY(180deg);
                background: url('./img/icon-back.png') no-repeat center / contain;
            }
        }
    }
}
</style>
