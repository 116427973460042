/**
 * 全局共享数据，初始化全局配置
 */
let listenerParam;
try {
    const opts = Object.defineProperty({}, 'passive', {
        get: function () {
            listenerParam = { passive: true };
            return true;
        },
    });
    addEventListener('test', null, opts);
} catch (e) {
    listenerParam = false;
}

import { webview } from '@kk/utils';
let userAgent = navigator.userAgent.toLowerCase();
const isApple = webview.isApple;
let globalData = {
    isAndroid: webview.isAndroid,
    isApple,
    isWeixin: webview.isWeixin,
    isQQ: /qq/.test(userAgent),
    isWeibo: /weibo/.test(userAgent),
};

export default {
    listenerParam,
    ...globalData,
};
