var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('p',{staticClass:"account-balance"},[_vm._v(" 剩余 "),_c('span',{staticClass:"balance-num font-medium"},[_vm._v(_vm._s(_vm.accountBalance))]),_vm._v(" 书币 ")]),_c('div',{staticClass:"discount-charge"},_vm._l((_vm.discountRechargeGoods),function(item){return _c('discount-charge-item',{key:item.id,staticClass:"discount-item",class:{ active: _vm.activedGoodId == item.id },attrs:{"item":item,"PrePage":_vm.PrePage},on:{"choose":_vm.onChoose}})}),1),_c('div',{key:"charge-list",staticClass:"charge-wrap"},[(_vm.chargeList.length > 0)?_c('div',{staticClass:"mod-charge"},[_vm._m(0),_c('div',{staticClass:"charge-contents"},_vm._l((_vm.chargeList),function(item){return _c('charge-item',{key:item.id,staticClass:"charge-item",class:{
                        active:
                            _vm.activedGoodId == item.id &&
                            _vm.activedGoodType === _vm.CHARGE_GOOD_TYPE_KKB,
                    },attrs:{"item":item},on:{"choose":_vm.onChoose}})}),1)]):_vm._e(),(_vm.vipRechargeList.length > 0)?_c('p',{staticClass:"vip-charge-text"},[_vm._v("专属定制读嗨全书城")]):_vm._e(),(_vm.vipRechargeList.length > 0)?_c('div',{staticClass:"multi-vip-charge-items"},_vm._l((_vm.vipRechargeList),function(vipRechargeItem){return _c('vip-charge-item',{key:vipRechargeItem.id,class:("charge-goods " + _vm.vipGoodItemClass + " " + (_vm.activedGoodId == vipRechargeItem.id &&
                    _vm.activedGoodType === _vm.CHARGE_GOOD_TYPE_VIP &&
                    !_vm.curChargeTarget.parentId
                        ? 'active'
                        : '')),attrs:{"vipRecharge":vipRechargeItem,"vipRechargeItemLength":_vm.vipRechargeList.length,"PrePage":_vm.PrePage},on:{"choose":_vm.onChoose}})}),1):_vm._e(),_c('ChargeWay',{staticClass:"mod-charge",attrs:{"payTypes":_vm.payTypes},on:{"change":_vm.onChangeChargeType}}),_c('rules',{attrs:{"topicPrice":_vm.topicPrice}})],1),_c('div',{key:"charge-btn",staticClass:"charge-btn-wrap"},[_c('div',{staticClass:"font-medium charge-btn",on:{"click":function($event){return _vm.doCharge()}}},[_vm._v(" 立即充值："+_vm._s(_vm.realPrice)+"元 ")])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('p',{staticClass:"charge-text"},[_vm._v(" 请选择充值金额 "),_c('span',[_vm._v("（1元=100书币）")])])}]

export { render, staticRenderFns }